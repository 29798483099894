import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, shareReplay, takeUntil } from 'rxjs';
import { RouteNames } from 'src/app/config/route-names';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() searchResultsUrl = RouteNames.MINISTERS;
  @Input() isAdvancedSearch = false;
  @Input() minChars = 3;
  @Input() maxChars = 240;

  @Output() searchTextChanged = new EventEmitter<string>();
  @Output() searchTextCleared = new EventEmitter<void>();
  @ViewChild('query', { read: ElementRef }) queryInputRef!: ElementRef;

  private isInputInvalidSubject = new BehaviorSubject(false);
  public isInputInvalid$ = this.isInputInvalidSubject
    .asObservable()
    .pipe(shareReplay(1));

  public searchTextControl = new FormControl('', {
    nonNullable: true
  });

  @Input()
  set searchText(value: string) {
    this.searchTextControl.setValue(value);
  }

  private setInputFocusSubject = new Subject<void>();
  private setInputFocus$ = this.setInputFocusSubject.asObservable();
  private destroy$ = new Subject<void>();

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['searchText'] &&
      changes['searchText'].currentValue.trim() === ''
    ) {
      this.setInputFocusSubject.next();
    }
  }

  ngAfterViewInit(): void {
    this.setInputFocus$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() =>
        (<HTMLInputElement>this.queryInputRef.nativeElement).focus()
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSearch(): void {
    const searchText = this.searchTextControl.value.trim();

    if (searchText !== this.searchTextControl.value) {
      this.searchTextControl.setValue(searchText);
    }

    const isInputInvalid = !!searchText && searchText.length < this.minChars;
    this.isInputInvalidSubject.next(isInputInvalid);

    if (isInputInvalid) {
      return;
    }

    if (this.isAdvancedSearch) {
      this.searchTextChanged.emit(searchText);
    } else {
      this.redirectToAdvancedSearch(this.searchResultsUrl, {
        q: searchText,
      });
    }
  }

  public onFocusIn(): void {
    this.isInputInvalidSubject.next(false);
  }

  public onInputClear(): void {
    this.searchTextControl.setValue('');
    this.setInputFocusSubject.next();
    this.searchTextCleared.emit();
  }

  private redirectToAdvancedSearch(
    url: string,
    queryParams: { [key: string]: string }
  ): void {
    this.router.navigate([url], {
      queryParams: queryParams,
    });
  }
}
