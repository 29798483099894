import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouteNames } from 'src/app/config/route-names';
import {
  MinisterListFilters,
  MinsiterListQueryParamsUIState,
  convertQueryParamsToFilters,
} from 'src/app/core/filters';
import { DropdownItem } from 'src/app/interfaces/dropdown-item.interface';
import { MembersFiltersQueryModel } from './members-filters.query-model';
import { MembersQueryModel } from './members.query-model';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersComponent {
  public routeNames = RouteNames;
  public itemsPerPage$: Observable<DropdownItem[]> = of([
    { label: 'Pokaż 10 elementów na stronie', value: '10' },
    { label: 'Pokaż 25 elementów na stronie', value: '25' },
    { label: 'Pokaż 50 elementów na stronie', value: '50' },
  ]);

  public sortOptions$: Observable<DropdownItem[]> =
    this.activatedRoute.data.pipe(
      map(data => {
        return data['members']['sortOptions'] as DropdownItem[];
      })
    );

  public data$: Observable<MembersQueryModel> = this.activatedRoute.data.pipe(
    map(data => {
      return data['members']['list'] as MembersQueryModel;
    })
  );

  public qpFilters$ = this.activatedRoute.queryParams.pipe(
    map(qParams =>
      Object.keys(qParams)
        .filter(key => key.startsWith('f'))
        .reduce(
          (currFilters, key) => ({ ...currFilters, [key]: qParams[key] }),
          {}
        )
    ),
    map(qParams => convertQueryParamsToFilters(qParams))
  );

  constructor(
    public activatedRoute: ActivatedRoute,
    private ministerListQueryParamsState: MinsiterListQueryParamsUIState
  ) {}

  public onFiltersChange(filters: MinisterListFilters) {
    this.ministerListQueryParamsState.updateQueryParams({
      ...filters,
      page: MembersFiltersQueryModel.defaultPage,
    });
  }

  public onPageChange(page: number): void {
    this.ministerListQueryParamsState.updateQueryParams({
      page,
    });
  }

  public onPerPageChange(item: DropdownItem): void {
    this.ministerListQueryParamsState.updateQueryParams({
      page: MembersFiltersQueryModel.defaultPage,
      pageSize: +item.value,
    });
  }

  public onSortChange(item: DropdownItem): void {
    this.ministerListQueryParamsState.updateQueryParams({
      page: MembersFiltersQueryModel.defaultPage,
      sort: item.value,
    });
  }

  public onSearchChange(query: string): void {
    this.ministerListQueryParamsState.updateQueryParams({
      page: MembersFiltersQueryModel.defaultPage,
      query,
    });
  }

  public onSearchClear(): void {
    this.ministerListQueryParamsState.updateQueryParams({
      page: MembersFiltersQueryModel.defaultPage,
      query: '',
    });
  }
}
