import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { NEVER, Observable, catchError } from 'rxjs';
import { Router } from '@angular/router';
import { RouteNames } from '../config/route-names';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept<T, K>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<K>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.router.navigate(['/', RouteNames.PAGE_NOT_FOUND]);
        } else if (error.status >= 500) {
          this.router.navigate(['/', RouteNames.SERVER_ERROR]);
        }
        // TODO: With Never in case of error we are not able 
        // to handle different errors than 404 and 500
        return NEVER;
      }),
    );
  }
}
