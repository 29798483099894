<div class="container-content">
  <!-- breadcrumbs -->
  <app-breadcrumbs></app-breadcrumbs>

  <!-- page heading -->
  <h1 class="heading-2 page-heading">Skontaktuj się z nami</h1>

  <div class="contact">
    <!-- heading -->
    <h2 class="heading-4 contact__heading">
      Masz ciekawe informacje?
      <span class="contact__heading-part"> Napisz do nas </span>
    </h2>

    <!-- description -->
    <p class="contact__description">
      Jeśli masz pytania lub ciekawe materiały na temat rządów
      <span class="contact__description-part">
        Rzeczypospolitej Polskiej, napisz na adres:
      </span>

      <span class="contact__description-row">
        <a class="contact__link" href="mailto:{{ email }}">
          {{ email }}
        </a>
      </span>
    </p>
  </div>
</div>
