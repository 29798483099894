import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, takeUntil, tap } from 'rxjs';
import { Router } from '@angular/router';
import { LocationMinisterInterface, MemorialLocationInterface } from '../interfaces/memorial-location.interface';
import { MinisterSlugPipe } from '../pipes/minister-slug.pipe';
import { LatLngTuple, LeafletEvent, Map, MapOptions } from 'leaflet';
import { createMarkerClusterIcon, defaultMapOptions, markerIcon } from '../configs/grave-map.config';
import { RouteNames } from '../config/route-names';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import GestureHandling from 'leaflet-gesture-handling';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  @Output() public map$: EventEmitter<Map> = new EventEmitter();
  @Output() public zoom$: EventEmitter<number> = new EventEmitter();
  @Input() options: MapOptions = defaultMapOptions;
  @Input() showDetails = false;
  private _map?: Map;
  private _markerLayer?: L.MarkerClusterGroup;

  private _destroySubject: Subject<void> = new Subject<void>();
  private _mapLoadSubject: Subject<void> = new Subject<void>();

  private _memorialsSubject: BehaviorSubject<MemorialLocationInterface[]>
    = new BehaviorSubject<MemorialLocationInterface[]>([]);
  private _memorials$: Observable<MemorialLocationInterface[]>
    = this._memorialsSubject.asObservable();

  @Input()
  set memorials(memorials: MemorialLocationInterface[]) {
    this._memorialsSubject.next(memorials);
  }

  constructor(
    private _router: Router,
    private _ministerSlugPipe: MinisterSlugPipe<LocationMinisterInterface>
  ) { }

  ngOnInit(): void {
    combineLatest([
      this._memorials$,
      this._mapLoadSubject.asObservable()
    ]).pipe(
      tap(([memorials]) => {
        this._removeMarkers();
        this._addMarkers(memorials);

        this._map?.setView(this.options.center || [34, 19], this.options.zoom || 2);
      }),
      takeUntil(this._destroySubject)
    ).subscribe();
  }

  ngOnDestroy() {
    this._destroySubject.next();
    this._destroySubject.complete();
    this._map?.clearAllEventListeners();
  }

  public onMapReady(map: Map): void {
    this._map = map;
    this.map$.emit(map);
    this.zoom$.emit(map.getZoom());
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    this._mapLoadSubject.next();
  }

  public onMapZoomEnd(e: LeafletEvent): void {
    this.zoom$.emit(e.target.getZoom());
  }

  private _removeMarkers(): void {
    if (!this._markerLayer) return;

    this._map?.removeLayer(this._markerLayer);
  }

  private _addMarkers(memorials: MemorialLocationInterface[]): void {
    if (!this._map) return;

    this._markerLayer = L.markerClusterGroup({
      iconCreateFunction: createMarkerClusterIcon,
      showCoverageOnHover: false,
    });

    memorials.forEach(m => {
      const location = [
        m.memorialLocationMapGPSDTO.latitude,
        m.memorialLocationMapGPSDTO.longitude,
      ] as LatLngTuple;
      const marker = L.marker(location, { icon: markerIcon });

      const popUp = this._createPopup(m);
      marker.bindPopup(popUp, { closeButton: false });
      marker.on('click', () => {
        marker.openPopup();
        this._map?.panTo(location);
      });
      this._markerLayer && this._markerLayer.addLayer(marker);
    });
    this._map?.addLayer(this._markerLayer);
  }

  private _viewMinisterProfile(minister: LocationMinisterInterface): void {
    this._router.navigateByUrl(
      `${RouteNames.MINISTERS}/${this._ministerSlugPipe.transform(
        minister,
        'ministerId'
      )}`
    );
  }

  private _createPopup(memorial: MemorialLocationInterface): HTMLElement {
    const { firstName, secondName, lastName } =
      memorial.memorialLocationMapMinisterDTO;
    const displayName = `${firstName} ${secondName || ''} ${lastName}`;

    const popupEl = document.createElement('div');
    popupEl.innerHTML = `<p class="popup-name">${displayName}</p><p class="popup-type">${memorial.type}</p>`;

    if (this.showDetails) {
      popupEl.innerHTML += `<button class="popup-button">Zobacz profil <span class="sr-only">${displayName}</span></button>`;
      popupEl.children[2].addEventListener('click', () => {
        this._viewMinisterProfile(memorial.memorialLocationMapMinisterDTO);
      });
    }
    return popupEl;
  }
}
