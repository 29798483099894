<div class="dropdown" tabindex="-1" id="dropdown-{{ uniqueId }}" *ngIf="indexes$ | async as indexes">
  <button
    type="button"
    class="dropdown__expander"
    [ngClass]="{ 
      'dropdown__expander--expanded': isExpanded$ | async, 
      'dropdown__expander--selected': showClearButton && indexes.selected !== -1 
    }"
    [attr.aria-expanded]="isExpanded$ | async"
    [attr.aria-haspopup]="true"
    attr.aria-controls="dropdown-menu-{{ uniqueId }}"
    (click)="onExpanderClick()"
    (keydown)="onExpanderKeydown($event)">

    <span class="dropdown__expander-label">
      
      <!-- selected -->
      <ng-container *ngIf="indexes.selected !== -1 && selectedItemAsLabel">
        <span class="sr-only">
          {{ selectedItemLabel }}
        </span>

        {{ items[indexes.selected].label }}
      </ng-container>

      <!-- not selected -->
      <ng-container *ngIf="indexes.selected === -1 || !selectedItemAsLabel">
        {{ expanderLabel }}
      </ng-container>
    </span>
  </button>

  <!-- clear button -->
  <button class="dropdown__clear" (click)="onClearClick()" id="dropdown-clear-{{ uniqueId }}" *ngIf="showClearButton && indexes.selected !== -1">
    <span class="sr-only">
      Wyczyść pole wyboru
    </span>
  </button>

  <!-- dropdown menu -->
  <ul
    class="dropdown__menu"
    role="listbox"
    id="dropdown-menu-{{ uniqueId }}"
    *ngIf="isExpanded$ | async">
    <li
      class="dropdown__item"
      role="option"
      tabindex="0"
      *ngFor="let item of items; let index = index"
      [ngClass]="{
        active: markSelectedItem && index === indexes.selected,
        current: index != indexes.selected && index === indexes.current
      }"
      [attr.aria-selected]="markSelectedItem && index === indexes.selected"
      (mouseenter)="setCurrentIndex(index)"
      (mouseleave)="setCurrentIndex(-1)"
      (click)="onItemClick()"
      (keydown)="onItemKeydown($event)">
      {{ item.label }}
    </li>
  </ul>
</div>
