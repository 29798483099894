import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class CustomTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    const appName = 'Rządy Rzeczypospolitej Polskiej';
    if (title !== undefined) {
      this.title.setTitle(`${title} - ${appName}`);
    } else {
      this.title.setTitle(`${appName}`);
    }
  }
}
