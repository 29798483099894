import {
  ActivatedRouteSnapshot,
  Params,
  ResolveFn,
  Router,
} from '@angular/router';
import { GovernmentsQueryModel } from './governments.query-model';
import { DropdownItem } from 'src/app/interfaces/dropdown-item.interface';
import { GovernmentsFiltersQueryModel } from './governments-filters.query-model';
import { GovernmentsService } from 'src/app/services/governments/governments.service';
import { inject } from '@angular/core';
import { catchError, map, of, switchMap, take } from 'rxjs';
import { HistoricalPeriodsService } from 'src/app/services/historical-periods/historical-periods.service';
import { RouteNames } from 'src/app/config/route-names';

export const governmentsResolver: ResolveFn<
  | {
      query: GovernmentsQueryModel;
      sortOptions: DropdownItem[];
    }
  | undefined
> = (activatedRoute: ActivatedRouteSnapshot) => {
  const governmentsService = inject(GovernmentsService);
  const historicalPeriodsService = inject(HistoricalPeriodsService);
  const router = inject(Router);

  const sortOptions: DropdownItem[] = [
    { label: 'Od najnowszych', value: 'desc' },
    { label: 'Od najstarszych', value: 'asc' },
  ];

  const slug = activatedRoute.params['slug'];

  const queryParamsModel = mapQueryParamsToGovernmentsFiltersQueryModel(
    activatedRoute.queryParams
  );

  return historicalPeriodsService.getOne(slug).pipe(
    take(1),
    switchMap(historicalPeriod =>
      governmentsService
        .getGovernmentByPeriod(historicalPeriod.name, queryParamsModel.sort)
        .pipe(
          map(response => ({
            query: {
              governments: response,
              filters: queryParamsModel,
              historicalPeriod,
            },
            sortOptions,
          }))
        )
    ),
    catchError(() => {
      router.navigate(['/', RouteNames.PAGE_NOT_FOUND]);
      return of(undefined);
    })
  );
};

const mapQueryParamsToGovernmentsFiltersQueryModel = (
  queryParams: Params
): GovernmentsFiltersQueryModel => {
  const defaultSort = 'desc';
  let sort = queryParams['s'];

  if (
    (sort && sort.toLowerCase() !== 'asc' && sort.toLowerCase() !== 'desc') ||
    !sort
  ) {
    sort = defaultSort;
  }

  return {
    sort,
  };
};
