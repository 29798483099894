<div
  class="year-picker"
  role="dialog"
  aria-modal="true"
  aria-label="Wybierz rok"
  *ngIf="isVisible$ | async">
  <div class="year-picker__header">
    <button
      class="year-picker__tab-button"
      aria-label="Poprzednie lata"
      (click)="showPrevValues()"
      (keydown)="onPrevButtonKeydown($event)"
      [disabled]="prevDisabled$ | async">
      <span
        class="gov-icon gov-icon--arrow-left-thin"
        aria-hidden="true"></span>
    </button>
    <span *ngIf="startValue$ | async as startValue"
      >{{ startValue }}-{{ startValue + valuesCount - 1 }}</span
    >
    <button
      class="year-picker__tab-button"
      (click)="showNextValues()"
      aria-label="Następne lata"
      [disabled]="nextDisabled$ | async">
      <span
        class="gov-icon gov-icon--arrow-right-thin"
        aria-hidden="true"></span>
    </button>
  </div>
  <ul class="year-picker__list">
    <li
      class="year-picker__item"
      [attr.aria-selected]="value === item"
      *ngFor="let item of values$ | async; let last = last">
      <button
        class="year-picker__button"
        (click)="selectValue(item)"
        [disabled]="
          (min !== undefined && min > item) || (max !== undefined && max < item) || item > maxAllowed
        "
        (keydown)="onValueButtonKeydown($event, item, last)">
        {{ item }}
      </button>
    </li>
  </ul>
</div>
