export interface MinisterListFiltersYearsActive {
  start: number | undefined;
  end: number | undefined;
}

export interface MinisterListFiltersInstitution {
  readonly name: string;
  readonly termBeginning: string;
  readonly termEnd: string | undefined;
}

export interface MinisterListFilters {
  historicalPeriodName: string;
  governmentName: string;
  functionName: string;
  institution: MinisterListFiltersInstitution;
  isDead: boolean;
  isCurrentFunction: boolean;
  yearsActive: MinisterListFiltersYearsActive;
}

export interface MinisterListFilter {
  name: string;
  value: string | boolean | MinisterListFiltersYearsActive | MinisterListFiltersInstitution;
}

export enum MinisterListFilterType {
  HISTORIC_PERIOD = 'historicalPeriodName',
  GOVERNMENT = 'governmentName',
  FUNCT = 'functionName',
  INSTITUTION = 'institution',
  ONLY_DEAD = 'isDead',
  ONLY_CURRENT_FUNCTION = 'isCurrentFunction',
  YEARS_ACTIVE = 'yearsActive',
}

export enum MinisterListFilterQueryParam {
  FUNCT = 'ff',
  GOVERNMENT = 'fg',
  HISTORIC_PERIOD = 'fhp',
  INSTITUTION_NAME = 'fi',
  INSTITUTION_BEGINNING = 'fib',
  INSTITUTION_END = 'fie',
  ONLY_DEAD = 'fod',
  ONLY_CURRENT_FUNCTION = 'focf',
  YEAR_START = 'fys',
  YEAR_END = 'fye',
}
