import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';
import {
  MinisterListFilters,
  MinsiterListQueryParamsUIState,
  convertQueryParamsToFilters,
} from 'src/app/core/filters';
import { MemorialLocationInterface } from 'src/app/interfaces/memorial-location.interface';
import { MembersFiltersQueryModel } from '../members/members-filters.query-model';

@Component({
  selector: 'app-memorial',
  templateUrl: './memorial.component.html',
  styleUrls: ['./memorial.component.scss'],
})
export class MemorialComponent {
  public qpFilters$ = this.activatedRoute.queryParams.pipe(
    map(qParams =>
      Object.keys(qParams)
        .filter(key => key.startsWith('f'))
        .reduce(
          (currFilters, key) => ({ ...currFilters, [key]: qParams[key] }),
          {}
        )
    ),
    map(qParams => convertQueryParamsToFilters(qParams))
  );

  public memorials$: Observable<MemorialLocationInterface[]> =
    this.activatedRoute.data.pipe(
      map(data => {
        return data['memorial'] as MemorialLocationInterface[];
      })
    );

  constructor(
    private activatedRoute: ActivatedRoute,
    private ministerListQueryParamsState: MinsiterListQueryParamsUIState
  ) {}

  public onSearchChange(query: string): void {
    this.ministerListQueryParamsState.updateQueryParams({
      page: MembersFiltersQueryModel.defaultPage,
      query,
    });
  }

  public onFiltersChange(filters: MinisterListFilters) {
    this.ministerListQueryParamsState.updateQueryParams({
      ...filters,
      page: MembersFiltersQueryModel.defaultPage,
    });
  }

  public onSearchClear(): void {
    this.ministerListQueryParamsState.updateQueryParams({
      page: MembersFiltersQueryModel.defaultPage,
      query: '',
    });
  }
}
