<article class="governments container-content" *ngIf="data$ | async as data">
  <!-- breadcrumbs -->
  <app-breadcrumbs class="governments__breadcrumbs"></app-breadcrumbs>

  <!-- page heading -->
  <h1 class="heading-2 page-heading">
    Lista rządów - {{ data.historicalPeriod.name }}
  </h1>

  <!-- sort -->
  <div class="governments__sort">
    <span class="governments__sort-label"> Sortowanie: </span>
    <app-dropdown
      [items]="(sortOptions$ | async)!"
      [selectedValue]="data.filters.sort"
      [selectedItemAsLabel]="true"
      (onDropdownItemClick)="onSortChange($event)">
    </app-dropdown>
  </div>

  <!-- governments list -->
  <ul class="governments__list">
    <li class="governments__item" *ngFor="let item of data.governments">
      <span class="governments__name">
        {{ item.name }}

        <span class="governments__date">
          ({{ item.termBeginning | date : 'dd.MM.yyyy' }} -
          {{ item.termEnd ? (item.termEnd | date : 'dd.MM.yyyy') : 'obecnie' }})
        </span>
      </span>

      <a
        [routerLink]="['/', routeNames.MINISTERS]"
        [queryParams]="{ fg: item.name }"
        class="link link--xl nowrap">
        Zobacz więcej
        <span class="sr-only"> o {{ item.name }} </span>
      </a>
    </li>
  </ul>
</article>
