import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { ProgressBarService } from '../services/progress-bar.service';

@Injectable()
export class ProgressBarInterceptor implements HttpInterceptor {
  constructor(private progressBarService: ProgressBarService) {}

  private readonly blackList = [
    'page/governments/historical-periods'
  ];

  intercept<T, K>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<K>> {
    const urlOnBlackList = this.blackList.find(url => request.url.endsWith(url));

    if (urlOnBlackList) {
      return next.handle(request);
    }

    this.progressBarService.show();

    return next
      .handle(request)
      .pipe(finalize(() => this.progressBarService.hide()));
  }
}
