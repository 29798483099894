<ng-container *ngIf="filters$ | async as filters">
  <section class="filters" *ngIf="dictionaries$ | async as dictionaries">
    <!-- toggle -->
    <button
      type="button"
      class="filters__toggle"
      (click)="onToggle()"
      [ngClass]="{ 'filters__toggle--open': isOpen$ | async }"
      [attr.aria-expanded]="isOpen$ | async"
      aria-haspopup="true">
      {{ (isOpen$ | async) ? 'Ukryj' : 'Pokaż' }} filtry

      <!-- counter -->
      <ng-container *ngIf="activeFiltersCounter$ | async as activeFiltersCounter">
        ({{ activeFiltersCounter }}<span class="sr-only">
          {{ activeFiltersCounter | declinate : 'aktywny' : 'aktywne' : 'aktywnych' }}
          {{ activeFiltersCounter | declinate : 'filtr' : 'filtry' : 'fitrów' }}</span>)
      </ng-container>

      <!-- icon -->
      <span class="gov-icon gov-icon--arrow-right-2-center filters__toggle-icon"></span>
    </button>

    <!-- clear -->
    <ng-container *ngIf="isClearAllBtnVisible$ | async">
      <button
        type="button"
        class="filters__clear"
        *ngIf="activeFiltersCounter$ | async as activeFiltersCounter"
        (click)="onClearAll()">
        Wyczyść filtry
        <span class="sr-only">
          {{ activeFiltersCounter }}
          {{
            activeFiltersCounter
              | declinate : 'aktywny' : 'aktywne' : 'aktywnych'
          }}
          {{ activeFiltersCounter | declinate : 'filtr' : 'filtry' : 'fitrów' }}
        </span>
        <span class="filters__clear-icon" aria-hidden="true"></span>
      </button>
    </ng-container>

    <div
      class="filters__inner"
      [ngClass]="{ 'visibility-animated': isOpen$ | async }">
      <h2 class="sr-only">Filtrowanie</h2>

      <!-- row -->
      <div class="filters__row filters__row--1">
        <!-- col -->
        <div class="filters__col">
          <span class="filters__label"> Okres historyczny </span>
          <div class="filters__control">
            <app-dropdown
              [expanderLabel]="expanderDefaultLabel"
              [showClearButton]="true"
              [selectedItemAsLabel]="true"
              [markSelectedItem]="true"
              [items]="dictionaries.historicPeriods"
              [selectedValue]="filters.historicalPeriodName"
              (onDropdownItemClick)="
                onFilterValueChange({
                  name: ministerListFilterType.HISTORIC_PERIOD,
                  value: $event.value
                })
              "
              (onDropdownClearClick)="
                onFilterValueChange({
                  name: ministerListFilterType.HISTORIC_PERIOD,
                  value: ''
                })
              "></app-dropdown>
          </div>
        </div>
        <!-- col -->
        <div class="filters__col">
          <span class="filters__label"> Rząd Rzeczypospolitej Polskiej </span>
          <div class="filters__control">
            <app-dropdown
              [expanderLabel]="expanderDefaultLabel"
              [showClearButton]="true"
              [selectedItemAsLabel]="true"
              [markSelectedItem]="true"
              [items]="dictionaries.governments"
              [selectedValue]="filters.governmentName"
              (onDropdownItemClick)="
                onFilterValueChange({
                  name: ministerListFilterType.GOVERNMENT,
                  value: $event.value
                })
              "
              (onDropdownClearClick)="
                onFilterValueChange({
                  name: ministerListFilterType.GOVERNMENT,
                  value: ''
                })
              "></app-dropdown>
          </div>
        </div>
      </div>

      <!-- row -->
      <div class="filters__row filters__row--2">
        <!-- col -->
        <div class="filters__col">
          <span class="filters__label"> Lata urzędowania </span>
          <div class="filters__control">
            <app-year-range-picker
              [value]="filters.yearsActive"
              (onYearChange)="
                onFilterValueChange({
                  name: ministerListFilterType.YEARS_ACTIVE,
                  value: $event
                })
              "></app-year-range-picker>
          </div>
        </div>

        <!-- col -->
        <div class="filters__col">
          <span class="filters__label"> Funkcja </span>
          <div class="filters__control">
            <app-dropdown
              [expanderLabel]="expanderDefaultLabel"
              [showClearButton]="true"
              [selectedItemAsLabel]="true"
              [markSelectedItem]="true"
              [items]="dictionaries.functions"
              [selectedValue]="filters.functionName"
              (onDropdownItemClick)="
                onFilterValueChange({
                  name: ministerListFilterType.FUNCT,
                  value: $event.value
                })
              "
              (onDropdownClearClick)="
                onFilterValueChange({
                  name: ministerListFilterType.FUNCT,
                  value: ''
                })
              "></app-dropdown>
          </div>
        </div>

        <!-- col -->
        <div class="filters__col">
          <span class="filters__label"> Instytucja </span>
          <div class="filters__control">
            <app-dropdown
              [expanderLabel]="expanderDefaultLabel"
              [showClearButton]="true"
              [selectedItemAsLabel]="true"
              [markSelectedItem]="true"
              [items]="dictionaries.departments"
              [selectedValue]="filters.institution"
              (onDropdownItemClick)="
                onFilterValueChange({
                  name: ministerListFilterType.INSTITUTION,
                  value: $event.value
                })
              "
              (onDropdownClearClick)="
                onFilterValueChange({
                  name: ministerListFilterType.INSTITUTION,
                  value: { name: '', termBeginning: '', termEnd: undefined }
                })
              "></app-dropdown>
          </div>
        </div>

        <!-- col -->
        <!-- <div class="filters__col">
          <span class="filters__label"> Instytucja dziś</span>
          <div class="filters__control">
            <app-dropdown
              [expanderLabel]="expanderDefaultLabel"
              [showClearButton]="true"
              [selectedItemAsLabel]="true"
              [markSelectedItem]="true"
              [items]="dictionaries.departments"
              [selectedValue]="filters.currentInstitutionName"
              (onDropdownItemClick)="
                onFilterValueChange({
                  name: ministerListFilterType.CURRENT_INSTITUTION,
                  value: $event.value
                })
              "
              (onDropdownClearClick)="
                onFilterValueChange({
                  name: ministerListFilterType.CURRENT_INSTITUTION,
                  value: ''
                })
              "></app-dropdown>
          </div>
        </div> -->
      </div>

      <!-- row -->
      <div class="filters__row filters__row--3">
        <app-checkbox
          [label]="'Tylko zmarli'"
          [isChecked]="filters[ministerListFilterType.ONLY_DEAD]"
          (clicked)="
            onFilterValueChange({
              name: ministerListFilterType.ONLY_DEAD,
              value: $event
            })
          "></app-checkbox>
        <app-checkbox
          [label]="'Aktualnie pełni funkcję'"
          [isChecked]="filters[ministerListFilterType.ONLY_CURRENT_FUNCTION]"
          (clicked)="
            onFilterValueChange({
              name: ministerListFilterType.ONLY_CURRENT_FUNCTION,
              value: $event
            })
          "></app-checkbox>
      </div>
    </div>
  </section>
</ng-container>
