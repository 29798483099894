<div class="gallery">
  <div class="gallery-slider">
    <ul
      class="gallery-slides"
      #slidesList
      (touchstart)="onTouchStart($event)"
      (touchend)="onTouchEnd($event)"
      [ngStyle]="{ width: images.length * 100 + '%' }">
      <li
        *ngFor="let image of images"
        class="gallery-slide"
        [ngStyle]="{ width: 100 / images.length + '%' }">
        <div class="gallery-image-block" appImageZoom>
          <img
            #zoomable
            [src]="image.imageUrl"
            [alt]="image.alternativeDescription"
            class="gallery-image" />
        </div>

        <p *ngIf="image.description" class="image-description">
          {{ image.description }}
        </p>

        <p *ngIf="image.license" class="image-description">
          Autor/licencja:
          <span class="image-description-bold">{{ image.license }}</span>
        </p>
      </li>
    </ul>

    <!-- prev -->
    <button
      type="button"
      class="btn btn--plain gallery-action gallery-action-prev"
      [attr.aria-disabled]="(isFirstSlide$ | async) ? true : null"
      [ngClass]="{ inactive: isFirstSlide$ | async }"
      (click)="slideLeft()">
      <span class="sr-only"> Poprzedni slajd </span>
      <span
        class="gov-icon gov-icon--arrow-left-thin gallery-action-icon"
        aria-hidden="true"></span>
    </button>

    <!-- next -->
    <button
      type="button"
      class="btn btn--plain gallery-action gallery-action-next"
      [attr.aria-disabled]="(isLastSlide$ | async) ? true : null"
      [ngClass]="{ inactive: isLastSlide$ | async }"
      (click)="slideRight()">
      <span class="sr-only"> Następny slajd </span>
      <span
        class="gov-icon gov-icon--arrow-right-thin gallery-action-icon"
        aria-hidden="true"></span>
    </button>

    <div class="gallery-counter">
      {{ (activeIndex$ | async)! + 1 }}/{{ images.length }}
    </div>
  </div>

  <ul class="gallery-thumbnails">
    <li
      *ngFor="let image of images; let index = index"
      class="gallery-thumbnail">
      <button
        type="button"
        class="gallery-thumbnail-button"
        [ngClass]="{ active: index === (activeIndex$ | async) }"
        (click)="selectImage(index)">
        <img
          [src]="image.thumbnailUrl"
          [alt]="image.alternativeDescription"
          class="gallery-thumbnail-image" />
      </button>
    </li>
  </ul>
</div>
