export const dateFormatDotDMY =
  /^(3[01]|[12][0-9]|0[1-9])\.(1[012]|0[1-9])\.((?:18|19|20)\d{2})$/;
export const dateFormatDashYMD =
  /^((?:18|19|20)\d{2})\-(1[012]|0[1-9])\-(3[01]|[12][0-9]|0[1-9])$/;

export function convertDateStringDotToDash(date: string | null): string {
  if (!date || !dateFormatDotDMY.test(date)) {
    return '';
  }

  const [day, month, year] = date.split('.');
  return `${year}-${month}-${day}`;
}

export function convertDateStringDashToDot(date: string | null): string {
  if (!date || !dateFormatDashYMD.test(date)) {
    return '';
  }

  const [year, month, day] = date.split('-');
  return `${day}.${month}.${year}`;
}

export const parseDate = (dateString: string | null): Date | null => {
  if (!dateString) {
    return null;
  }

  if (dateFormatDotDMY.test(dateString)) {
    return new Date(convertDateStringDotToDash(dateString));
  } else if (dateFormatDashYMD.test(dateString)) {
    return new Date(dateString);
  }

  return null;
};

export interface SortArrByDateConfig<T> {
  dateField: keyof T;
  isDescending: boolean;
}

export const sortArr = (array: string[], isDescending: boolean): string[] => {
  return array.sort((a: string, b: string) => {
    const compared = a.localeCompare(b);

    if (compared === 0) return 0;

    if (compared < 0) return isDescending ? 1 : -1;

    return isDescending ? -1 : 1;
  });
};

export const sortArrByDate = <T>(
  array: T[],
  config: SortArrByDateConfig<T>
): T[] => {
  const sortedArr = array.slice();

  sortedArr.sort((a, b) => {
    const dateA = parseDate(a[config.dateField] as string | null);
    const dateB = parseDate(b[config.dateField] as string | null);

    if (dateA === null && dateB === null) {
      return 0;
    }

    if (dateA === null) {
      return config.isDescending ? -1 : 1;
    }

    if (dateB === null) {
      return config.isDescending ? 1 : -1;
    }

    return config.isDescending
      ? dateB.getTime() - dateA.getTime()
      : dateA.getTime() - dateB.getTime();
  });

  return sortedArr;
};

export interface SortByNameAndDateConfig<T> {
  nameKey: keyof T;
  dateKey: keyof T;
  isDescending: boolean;
}

export const sortByNameAndDate = <T>(
  arr: T[],
  config: SortByNameAndDateConfig<T>
): T[] => {
  return arr.sort((a, b) => {
    const nameA = String(a[config.nameKey]).toLowerCase();
    const nameB = String(b[config.nameKey]).toLowerCase();
    const dateA = parseDate(a[config.dateKey] as string | null);
    const dateB = parseDate(b[config.dateKey] as string | null);

    if (nameA < nameB) {
      return config.isDescending ? 1 : -1;
    }
    if (nameA > nameB) {
      return config.isDescending ? -1 : 1;
    }

    if (dateA === null && dateB === null) {
      return 0; // both dates are null - equal
    }

    if (dateA === null) {
      return config.isDescending ? 1 : -1; // dateA is null, treat as smaller
    }
    if (dateB === null) {
      return config.isDescending ? -1 : 1; // dateB is null, treat as smaller
    }

    if (dateA < dateB) {
      return config.isDescending ? 1 : -1;
    }
    if (dateA > dateB) {
      return config.isDescending ? -1 : 1;
    }

    return 0; // equal names and dates
  });
};
