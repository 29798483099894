import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { appConfigMerge } from './app/utils/app.utils';
import { APP_CONFIG } from './app/config/tokens';
import { CONFIG_URL } from './app/config/const';

fetch(CONFIG_URL)
  .then(res => res.json())
  .catch(() => ({}))
  .then(config => {
    platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: appConfigMerge(config) },
    ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
