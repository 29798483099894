import { Params } from '@angular/router';
import { MinisterListFilterQueryParam } from 'src/app/core/filters';

export class MemorialsFiltersQueryModel {
  private constructor(
    public readonly query: string,
    // filters
    public readonly functionName: string,
    public readonly governmentName: string,
    public readonly historicalPeriodName: string,
    public readonly institutionName: string | undefined,
    public readonly institutionTermBeginning: string | undefined,
    public readonly institutionTermEnd: string | undefined,
    public readonly isDead: boolean | undefined,
    public readonly isCurrentFunction: boolean | undefined,
    public readonly yearFromOffice: number | undefined,
    public readonly yearToOffice: number | undefined
  ) {}

  static fromQueryParams(queryParams: Params) {
    const query = queryParams['q'];

    // filters
    const functionName = queryParams[MinisterListFilterQueryParam.FUNCT];
    const governmentName = queryParams[MinisterListFilterQueryParam.GOVERNMENT];
    const historicalPeriodName =
      queryParams[MinisterListFilterQueryParam.HISTORIC_PERIOD];
      const institutionName =
      queryParams[MinisterListFilterQueryParam.INSTITUTION_NAME];
    const institutionTermBeginning =
      queryParams[MinisterListFilterQueryParam.INSTITUTION_BEGINNING];
    const institutionTermEnd =
      queryParams[MinisterListFilterQueryParam.INSTITUTION_END];
    const isDead =
      queryParams[MinisterListFilterQueryParam.ONLY_DEAD] &&
      queryParams[MinisterListFilterQueryParam.ONLY_DEAD].toLowerCase() ===
        'true'
        ? true
        : undefined;
    const isCurrentFunction =
      queryParams[MinisterListFilterQueryParam.ONLY_CURRENT_FUNCTION] &&
      queryParams[
        MinisterListFilterQueryParam.ONLY_CURRENT_FUNCTION
      ].toLowerCase() === 'true'
        ? true
        : undefined;
    const yearFromOffice = queryParams[MinisterListFilterQueryParam.YEAR_START];
    const yearToOffice = queryParams[MinisterListFilterQueryParam.YEAR_END];
    /// filters

    return new this(
      query,
      functionName,
      governmentName,
      historicalPeriodName,
      institutionName,
      institutionTermBeginning,
      institutionTermEnd,
      isDead,
      isCurrentFunction,
      yearFromOffice,
      yearToOffice
    );
  }
}
