import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { RouteNames } from '../config/route-names';

@Pipe({
  name: 'isMinisterListRouteActive',
  pure: false,
})
export class IsMinisterListRouteActivePipe implements PipeTransform {
  transform(
    router: Router
  ): boolean {
   return router.isActive(RouteNames.GOVERNMENTS, false) ||
    router.isActive(RouteNames.MINISTERS, false);
  }
}
