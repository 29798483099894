<article class="container-content http-error http-error--500">
        
    <!-- page heading -->
    <h1 class="heading-2 page-heading http-error__heading">
        Coś poszło nie tak
    </h1>

    <!-- description -->
    <p class="http-error__description">
        Niestety, napotkaliśmy błąd.
        <span class="http-error__description-part">
            Przejdź do strony głównej lub spróbuj ponownie.
        </span>
    </p>

    <a class="link link--l link--btn-primary" [routerLink]="['/']">
        Przejdź do strony głównej
    </a>
</article>