import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

let uniqueId = 0;

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() totalPages = 1;
  @Input() currentPage = 1;

  @Output() pageChange = new EventEmitter();

  private currentPageSubject = new BehaviorSubject(1);
  public currentPage$ = this.currentPageSubject.asObservable();

  public readonly uniqueId = uniqueId++;

  ngOnChanges(changes: SimpleChanges) {
    this.currentPageSubject.next(changes['currentPage']?.currentValue || 1);
  }

  public onPageChange(page: number): void {
    if (page < 1) {
      page = 1;
    }

    if (page >= this.totalPages) {
      page = this.totalPages;
    }

    this.currentPageSubject.next(page);
    this.pageChange.emit(page);
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Enter') {
      return;
    }

    const value = (<HTMLInputElement>event.target).value;

    if (!value || isNaN(+value)) {
      this.onPageChange(1);
    } else {
      this.onPageChange(+value);
    }
  }
}
