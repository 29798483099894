import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { MinisterListFilters, clearEmptyFilters } from 'src/app/core/filters';
import { PageableContent } from 'src/app/shared/pagination/pageable-data';
import { PaginateParams } from 'src/app/shared/pagination/paginate-params';
import { MemorialInterface } from '../../interfaces/memorial.interface';
import { MinisterListItem } from '../../interfaces/minister-list.interface';
import { MinisterInterface } from '../../interfaces/minister.interface';
import { CacheService } from '../cache/cache.service';

type MinisterListAllQParams = PaginateParams & Partial<MinisterListFilters>;

@Injectable({
  providedIn: 'root',
})
export class MinistersService {
  constructor(private _http: HttpClient, private _cache: CacheService) {}

  public getMinisterMemorials(
    ministerId: string
  ): Observable<MemorialInterface[]> {
    return this._cache.remember(
      `MinistersService-getMinisterMemorials-${ministerId}`,
      this._http.get<MemorialInterface[]>(
        `page/ministers/${ministerId}/memorials`
      )
    );
  }

  public getMinister(ministerId: string): Observable<MinisterInterface> {
    return this._cache.remember(
      `MinistersService-getMinister-${ministerId}`,
      this._http.get<MinisterInterface>(`page/ministers/${ministerId}`)
    );
  }

  public getMinisters(
    params: MinisterListAllQParams
  ): Observable<PageableContent<MinisterListItem[]>> {
    if (params.page >= 1) {
      params.page = params.page - 1;
    }

    const updatedParams = clearEmptyFilters(params);

    return this._http
      .get<PageableContent<MinisterListItem[]>>('page/ministers', {
        params: new HttpParams({ fromObject: updatedParams as {} }),
      })
      .pipe(
        map(apiResponse => {
          return {
            ...apiResponse,
            pageable: {
              ...apiResponse.pageable,
              pageNumber: apiResponse.pageable.pageNumber + 1,
            },
          };
        }),
        shareReplay()
      );
  }
}
