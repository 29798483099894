import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay } from 'rxjs';

@Component({
  selector: 'app-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
})
export class YearPickerComponent {
  @Output() onValueClick = new EventEmitter<number>();
  @Output() onForceClose = new EventEmitter<void>();

  @Input() min?: number;
  @Input() max?: number;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: Event) {
    if (event instanceof KeyboardEvent) {
      switch (event.key) {
        case 'Escape':
          this.close();
          break;
      }
    }
  }

  public readonly minAllowed = 1;
  public readonly maxAllowed = 9999;
  public readonly valuesCount = 25;

  @Input()
  set value(value: number | undefined) {
    if (value === undefined) return;

    let startValue =
      Math.floor((value - 1) / this.valuesCount) * this.valuesCount + 1;
    if (startValue < this.minAllowed) {
      startValue = this.minAllowed;
    }
    if (startValue + this.valuesCount > this.maxAllowed + 1) {
      startValue = this.maxAllowed - this.valuesCount + 2;
    }
    this._startValueSubject.next(startValue);
  }

  private _startValueSubject = new BehaviorSubject<number>(2001);
  public startValue$ = this._startValueSubject
    .asObservable()
    .pipe(shareReplay(1));

  public prevDisabled$ = this.startValue$.pipe(
    map(startValue => startValue <= this.minAllowed)
  )
  public nextDisabled$ = this.startValue$.pipe(
    map(startValue => startValue + this.valuesCount > this.maxAllowed)
  )

  public values$ = this.startValue$.pipe(
    map(startValue =>
      Array.from(Array(this.valuesCount).keys()).map(v => v + startValue)
    )
  );

  private _isVisibleSubject = new BehaviorSubject<boolean>(false);
  public isVisible$: Observable<boolean> =
    this._isVisibleSubject.asObservable();

  selectValue(value: number) {
    this.onValueClick.emit(value);
  }

  showPrevValues() {
    const startValue = this._startValueSubject.getValue();
    this._startValueSubject.next(startValue - this.valuesCount);
  }

  showNextValues() {
    const startValue = this._startValueSubject.getValue();
    this._startValueSubject.next(startValue + this.valuesCount);
  }

  open() {
    this._isVisibleSubject.next(true);
  }

  close() {
    this._isVisibleSubject.next(false);
  }

  toggle() {
    const isVisible = this._isVisibleSubject.getValue();
    this._isVisibleSubject.next(!isVisible);
  }

  onPrevButtonKeydown(event: Event) {
    if (event instanceof KeyboardEvent) {
      switch (event.key) {
        case 'Tab':
          if (event.shiftKey) {
            this.close();
          }
          break;
      }
    }
  }

  onValueButtonKeydown(event: Event, value: number, last: boolean) {
    if (!last && value !== this.max) return;

    if (event instanceof KeyboardEvent) {
      switch (event.key) {
        case 'Tab':
          if (!event.shiftKey) {
            this.close();
            this.onForceClose.emit();
          }
          break;
      }
    }
  }
}
