import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouteNames } from 'src/app/config/route-names';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public routeNames = RouteNames;
  public email = 'rzadyRP@kprm.gov.pl';

  constructor(public router: Router){}
}
