<div class="container-content">
  <!-- breadcrumbs -->
  <app-breadcrumbs></app-breadcrumbs>

  <!-- details -->
  <div class="member" *ngIf="minister$ | async as data">
    <div class="member__info">
      <div class="member__info-image">
        <img
          [src]="data.minister.imageDTO.imageUrl"
          class="rounded member__image"
          [ngClass]="{ grayscale: data.minister.isDead }"
          [alt]="data.minister.imageDTO.alternativeDescription" />
        <p *ngIf="data.minister.imageDTO.license" class="image-description">
          Autor/licencja: <span class="image-description-bold">{{ data.minister.imageDTO.license }}</span>
        </p>
      </div>
      
      <div class="member__info-name">
        <a [routerLink]="['../']" class="link link--back member__back">
          Przejdź do listy ministrów
        </a>

        <h1 class="heading-3 member__heading">
          {{ data.minister.firstName }}
          {{ data.minister.secondName }}
          {{ data.minister.lastName }}
        </h1>
      </div>

      <!-- biography -->
      <div class="member__personal">
  
        <!-- birth/death -->
        <div class="member__dates">
  
          <!-- birth -->
          <div class="member__dates-birth">
            <div class="member__personal-label">Data urodzenia</div>
            <ng-container *ngIf="data.minister.dateOfBirth">
              {{ data.minister.dateOfBirth }}
            </ng-container>
            <abbr title="brak danych" *ngIf="!data.minister.dateOfBirth">b.d.</abbr>
          </div>
  
          <!-- death -->
          <div class="member__dates-death" *ngIf="data.minister.isDead">
            <div class="member__personal-label">Data śmierci</div>
            {{ data.minister.dateOfDeath }}
  
            <div *ngIf="data.memorials.length">
              <a
                [routerLink]="[]"
                class="link link--plain member__dates-link"
                (click)="goToMemorial()">
                Zobacz miejsce pamięci
                <span class="sr-only">
                  dla {{ data.minister.firstName + ' ' + data.minister.lastName }}
                </span>
              </a>
            </div>
          </div>
        </div>
  
        <!-- biography -->
        <div class="member__personal-label member__personal-label--bio" tabindex="-1" #biographyHeader>Biografia</div>
        <div class="member__biography">
          <ng-container *ngIf="data.minister.biography">
            <ng-container *ngIf="bioExcerptChars < data.minister.biography.length; else noExcerpt">
              <!-- bio excerpt -->
              <div tabindex="-1" [innerHTML]="data.minister.biography | truncateText:bioExcerptChars | newLine2br | sanitizeHtml" *ngIf="isBioExcerptVisible$ | async"></div>

              <!-- full bio -->
              <div tabindex="-1" [innerHTML]="data.minister.biography | newLine2br | sanitizeHtml" *ngIf="(isBioExcerptVisible$ | async) === false"></div>

              <!-- bio toggle -->
              <button type="button" class="member__biography-toggle" (click)="onBioToggle()" 
                [ngClass]="{'member__biography-toggle--expanded': (isBioExcerptVisible$ | async) === false}">
                Pokaż {{ (isBioExcerptVisible$ | async) ? 'więcej' : 'mniej' }}
                <span class="gov-icon gov-icon--arrow-right-2-center member__biography-icon"></span>
              </button>
            </ng-container>

            <!-- no excerpt - always full bio -->
            <ng-template #noExcerpt>
              <div [innerHTML]="data.minister.biography | newLine2br | sanitizeHtml"></div>
            </ng-template>
          </ng-container>
        </div>

      </div>
    </div>

    <h2 class="heading-4 member__sub-heading">Funkcje w Radzie Ministrów</h2>

    <!-- function -->
    <div
      class="member__container member__container--top-spacing"
      *ngFor="let fun of data.minister.functions">
      <div class="member__container--period">
        {{ fun.periodOfOfficeFromDate }}-<!-- prevent extra space
            --><span [ngClass]="{ 'to-now': !fun.periodOfOfficeToDate }"
          ><!-- prevent extra space
              -->{{
            fun.periodOfOfficeToDate ? fun.periodOfOfficeToDate : 'teraz'
          }}
        </span>
      </div>
      <div class="member__container-right">
        <div class="member__function">
          <span
            class="member__function--dot"
            [ngClass]="{ full: !fun.periodOfOfficeToDate }"></span>
          <span
            *ngIf="!fun.periodOfOfficeToDate"
            class="member__function--dot highlighted"></span>
          <div class="member__function-title">
            <h3 class="heading-6">{{ fun.name }}</h3>
          </div>
          <div class="results__details-row">
            <span class="labelled-group labelled-group--first">
              Instytucja
              <span class="labelled-group__value">
                {{ fun.historicalInstitutionName }}
              </span>
            </span>
          </div>
          <div class="results__details-row">
            <span class="labelled-group">
              Rząd
              <span class="labelled-group__value">
                {{ fun.governmentName }}
              </span>
            </span>
            <span class="labelled-group">
              Lata urzędowania
              <span class="labelled-group__value">
                {{ fun.periodOfOfficeFromDate }}-{{
                  fun.periodOfOfficeToDate ? fun.periodOfOfficeToDate : 'teraz'
                }}
              </span>
            </span>
            <span class="labelled-group">
              Okres historyczny
              <span class="labelled-group__value">
                {{ fun.historicalPeriodName }}
              </span>
            </span>
          </div>
          <div *ngIf="fun.affairs?.length" class="results__details-row">
            <table class="labelled-table">
              <thead>
                <tr>
                  <th>Sprawa rządowa</th>
                  <th>Instytucja dziś</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let affair of fun.affairs">
                  <td>
                    <p *ngFor="let affairName of affair.affairNames">{{ affairName }}</p>
                  </td>
                  <td>{{ affair.actualInstitutionName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- memorialize -->
    <section *ngIf="data.memorials.length" class="member__memorialize">
      <h2 class="heading-4 m-0" #memorialsEl>Miejsce pamięci</h2>

      <!-- grave -->
      <section
        class="member__grave"
        *ngFor="let m of data.memorials; let mi = index">
        <div class="member__grave-data" [ngClass]="{'member__grave-data--no-gallery': !m.images.length}">
          <h3 class="heading-5 m-0">
            {{ m.type }}
          </h3>
          <div class="results__details-row results__details-row--main">
            <span class="labelled-group labelled-group--l">
              Kraj
              <span class="labelled-group__value">
                {{ m.country }}
              </span>
            </span>
            <span class="labelled-group labelled-group--l">
              Miejscowość
              <span class="labelled-group__value">
                {{ m.locality }}
              </span>
            </span>
            <span class="labelled-group labelled-group--l labelled-group--wide" *ngIf="!m.images.length">
              Lokalizacja
              <span class="labelled-group__value">
                {{ m.localization }}
              </span>
            </span>
          </div>
          <div class="labelled-group labelled-group--l" *ngIf="m.images.length">
            Lokalizacja
            <span class="labelled-group__value">
              {{ m.localization }}
            </span>
          </div>
          <p class="labelled-group labelled-group--l" *ngIf="m.gpsLocation">
            Współrzędne GPS
            <span class="labelled-group__value">
              {{ m.gpsLocation }}
            </span>
          </p>
          <div class="labelled-group labelled-group--l" *ngIf="m.localizationDescription">
            Opis lokalizacji
            <p class="labelled-group__value">
              {{ m.localizationDescription }}
            </p>
          </div>
        </div>

        <!-- gallery -->
        <app-gallery [images]="m.images"></app-gallery>
      </section>
    </section>

    <!-- map -->
    <section *ngIf="data.minister.hasMapLocations" class="member__map" id="map">
      <div class="member__map-inner">
        <h2 class="heading-4 m-0">Lokalizacje miejsc pamięci</h2>
      </div>

      <!-- map -->
      <app-map
        class="member__map-container"
        [memorials]="data.mapMemorials"></app-map>

      <a
        [routerLink]="['/', routeNames.MEMORIALS]"
        class="link member__map-link">
        Zobacz mapę wszystkich miejsc pamięci
      </a>
    </section>

    <!-- write to us -->
    <section class="linked member__contact" id="write-to-us">
      <h2 class="home-heading linked__heading">
        Masz ciekawe informacje?
        <span class="d-block"> Napisz do nas </span>
      </h2>
      <p class="linked__description">
        Skontaktuj się z nami, jeśli masz informacje,
        <span class="d-block"> których nie ma na stronie. </span>
      </p>
      <a
        [routerLink]="['/', routeNames.CONTACT]"
        class="link link--l link--btn">
        Napisz do nas wiadomość
      </a>
    </section>
  </div>
</div>
