import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay, throwError } from 'rxjs';
import { HistoricalPeriodDto } from 'src/app/interfaces/historical-period.dto';
import { HistoricalPeriod } from 'src/app/interfaces/historical-period.interface';
import { slugify } from 'src/app/utils/text.utils';
import { CacheService } from '../cache/cache.service';

@Injectable({
  providedIn: 'root',
})
export class HistoricalPeriodsService {
  constructor(private _http: HttpClient, private _cache: CacheService) {}

  public getAll(): Observable<HistoricalPeriod[]> {
    return this._cache.remember(
      `HistoricalPeriodsService-getAll`,
      this._http
        .get<HistoricalPeriodDto[]>('page/governments/historical-periods')
        .pipe(
          map(periods =>
            periods.map(period => ({
              ...period,
              slug: slugify(period.name),
            }))
          ),
          shareReplay(1)
        )
    );
  }

  public getOne(slug: string): Observable<HistoricalPeriod> {
    return this.getAll().pipe(
      map(periods => {
        const period = periods.find(period => period.slug === slug);

        if (period === undefined) {
          throwError(() => new Error('not found'));
        }

        return period as HistoricalPeriod;
      }),
    );
  }
}
