import {
  DivIcon,
  Icon,
  latLng,
  MapOptions,
  MarkerCluster,
  tileLayer,
} from 'leaflet';
import * as L from 'leaflet';

export function createMarkerClusterIcon(
  clusterMarkers: MarkerCluster
): DivIcon {
  const count = clusterMarkers.getChildCount();
  const icon = count > 99 ? icons.big : count > 9 ? icons.md : icons.sm;
  const html = getMarkerClusterTemplate(count);
  return L.divIcon({
    className: `cluster-marker ${icon.className}`,
    html: html,
    iconSize: [icon.size, icon.size],
  });
}

type CustomMapOptions = MapOptions & {
  gestureHandling: boolean;
  gestureHandlingOptions?: {
    duration: number; // doesn't work on iphone. check `_map.scss` file instead
    text: { touch: string; scroll: string; scrollMac: string };
  };
};

export const defaultMapOptions: CustomMapOptions = {
  layers: [
    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      opacity: 0.7,
      maxZoom: 19,
      detectRetina: true,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright" rel="noopener">OpenStreetMap</a> contributors',
    }),
  ],
  zoom: 2,
  center: latLng(34, 19),
  dragging: !L.Browser.mobile,
  tap: !L.Browser.mobile,
  gestureHandling: L.Browser.mobile,
};

export const markerIcon: Icon = L.icon({
  iconUrl: 'assets/img/marker-icon.png',
  iconSize: [37, 37],
});

const icons = {
  big: { className: 'big', size: 56 },
  md: { className: 'md', size: 48 },
  sm: { className: 'sm', size: 40 },
};

function getMarkerClusterTemplate(count: number): string {
  return `<div class="circle"></div><span class="number">${count.toString()}</span>`;
}
