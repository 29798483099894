import {
  ContentChild,
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
} from '@angular/core';

interface MousePosition {
  left: number;
  top: number;
}

@Directive({
  selector: '[appImageZoom]',
})
export class ImageZoomDirective {
  private readonly mobileBreakpoint = 768;
  private isEnabled = false;
  private isZoomed = false;
  private isMouseDown = false;
  private isMouseMove = false;
  private currentPosition: MousePosition = {
    left: 0,
    top: 0,
  };
  private startPosition: MousePosition | null = null;

  @ContentChild('zoomable')
  public img!: ElementRef;

  @HostListener('click')
  public onClick(): void {
    if (!this.isEnabled) return;
    if (this.isMouseMove) {
      this.isMouseMove = false;
      return;
    }
    this.isZoomed = !this.isZoomed;

    this.startPosition = null;
    this.currentPosition = {
      left: 0,
      top: 0,
    };

    this.setZoom();
    this.setCursor();
  }

  @HostListener('mousedown', ['$event'])
  public onMouseDown(event: MouseEvent): void {
    event.preventDefault();
    if (!this.isEnabled || !this.isZoomed) return;

    this.isMouseDown = true;

    this.startPosition = {
      left: event.clientX,
      top: event.clientY,
    };
  }

  @HostListener('document:mouseup', ['$event'])
  public onMouseUp(event: MouseEvent): void {
    if (!this.isEnabled || !this.isZoomed || !this.isMouseDown || !this.startPosition) return;

    this.isMouseDown = false;

    const newPosition: MousePosition = {
      left: this.startPosition.left + this.currentPosition.left - event.clientX,
      top: this.startPosition.top + this.currentPosition.top - event.clientY,
    };

    this.startPosition = null;
    this.currentPosition = newPosition;
  }

  @HostListener('document:mousemove', ['$event'])
  public onMouseMove(event: MouseEvent): void {
    if (!this.isEnabled || !this.isZoomed || !this.isMouseDown || !this.startPosition) return;

    this.isMouseMove = true;

    const newPosition: MousePosition = {
      left: -(
        this.startPosition.left +
        this.currentPosition.left -
        event.clientX
      ),
      top: -(this.startPosition.top + this.currentPosition.top - event.clientY),
    };

    this.renderer.setStyle(
      this.img.nativeElement,
      'transform',
      `scale(2) translate(${newPosition.left / 2}px, ${newPosition.top / 2}px)`
    );
  }

  @HostListener('window:resize')
  onResize() {
    this.isEnabled = window.innerWidth > this.mobileBreakpoint;
    if (this.isEnabled) {
      this.enable();
    }
    else {
      this.disable();
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.onResize();
  }

  private disable(): void {
    this.renderer.removeStyle(this.el.nativeElement, 'cursor');
    this.renderer.removeStyle(this.el.nativeElement, 'overflow');
    this.isZoomed = false;
    this.setZoom();
  }

  private enable(): void {
    this.setCursor();
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
  }

  private setZoom(): void {
    if (this.isZoomed) {
      this.renderer.setStyle(this.img.nativeElement, 'transform', 'scale(2)');
    } else {
      this.renderer.removeStyle(this.img.nativeElement, 'transform');
    }
  }

  private setCursor(): void {
    this.renderer.setStyle(
      this.el.nativeElement,
      'cursor',
      this.isZoomed ? 'zoom-out' : 'zoom-in'
    );
  }
}
