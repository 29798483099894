import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapComponent } from './map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { MembersComponent } from './pages/members/members.component';
import { MemorialComponent } from './pages/memorial/memorial.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { MemberComponent } from './pages/member/member.component';
import { GovernmentsComponent } from './pages/governments/governments.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaqComponent } from './components/faq/faq.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UrlInterceptor } from './interceptors/url-interceptor.service';
import { SearchComponent } from './components/search/search.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { MinisterSlugPipe } from './pipes/minister-slug.pipe';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DeclinatePipe } from './pipes/declinate.pipe';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { MinisterListFiltersComponent } from './components/minister-list-filters/minister-list-filters.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { IsMinisterListRouteActivePipe } from './pipes/is-minister-route-active.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar'; 
import { ProgressBarInterceptor } from './interceptors/progress-bar.interceptor';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { NewLine2brPipe } from './pipes/new-line2br.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { YearRangePickerComponent } from './components/year-range-picker/year-range-picker.component';
import { YearPickerComponent } from './components/year-picker/year-picker.component';
import { GalleryModalComponent } from './components/gallery-modal/gallery-modal.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { DialogModule } from '@angular/cdk/dialog';
import { GallerySliderComponent } from './components/gallery-slider/gallery-slider.component';
import { ImageZoomDirective } from './directives/image-zoom.directive';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    MembersComponent,
    MemorialComponent,
    BreadcrumbsComponent,
    MemberComponent,
    GovernmentsComponent,
    PaginationComponent,
    FaqComponent,
    DropdownComponent,
    SearchComponent,
    CarouselComponent,
    MinisterSlugPipe,
    CheckboxComponent,
    DeclinatePipe,
    PageNotFoundComponent,
    ServerErrorComponent,
    MinisterListFiltersComponent,
    IsMinisterListRouteActivePipe,
    ProgressBarComponent,
    NewLine2brPipe,
    SanitizeHtmlPipe,
    TruncateTextPipe,
    YearRangePickerComponent,
    YearPickerComponent,
    GalleryModalComponent,
    GalleryComponent,
    GallerySliderComponent,
    ImageZoomDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LeafletModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    DialogModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressBarInterceptor,
      multi: true
    },
    {
      provide: Window,
      useValue: window,
    },
    MinisterSlugPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
