import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';
import { BreadcrumbItemInterface } from 'src/app/interfaces/breadcrumb-item.interface';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  breadCrumbs$: Observable<BreadcrumbItemInterface[]> =
    this._activatedRoute.url.pipe(
      map(() =>
        this._activatedRoute.snapshot.pathFromRoot
          .filter(route => !!route.title && !!route.url.length)
          .reduce(
            (a: BreadcrumbItemInterface[], route) => [
              ...a,
              {
                title: route.title,
                url:
                  '/' +
                  [...a.map(p => p.url), ...route.url.map(s => s.path)].join(
                    '/'
                  ),
              } as BreadcrumbItemInterface,
            ],
            []
          )
      )
    );

  constructor(private _activatedRoute: ActivatedRoute) {}
}
