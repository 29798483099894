import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _router: Router,
    @Inject(DOCUMENT) private _document: Document,
  ) {}
  
  ngOnInit(): void {
    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setFocusOnTop();
      });
  }

  private setFocusOnTop() {
    (<HTMLDivElement>this._document.querySelector('#app-top'))?.focus();
  }
}
