import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MembersFiltersQueryModel } from 'src/app/pages/members/members-filters.query-model';
import {
  MinisterListFilterQueryParam,
  MinisterListFilters,
} from '../model/minister-list-filters';

@Injectable({ providedIn: 'root' })
export class MinsiterListQueryParamsUIState {
  constructor(public activatedRoute: ActivatedRoute, private router: Router) {}

  public updateQueryParams(
    params: Partial<MembersFiltersQueryModel & MinisterListFilters>
  ): void {
    const queryParams: Params = {};
    if (params.page !== undefined) {
      queryParams['p'] = params.page;
    }
    if (params.pageSize !== undefined) {
      queryParams['ps'] = params.pageSize;
    }
    if (params.sort !== undefined) {
      queryParams['s'] = params.sort;
    }
    if (params.query !== undefined) {
      queryParams['q'] = params.query;
    }

    // filters
    if (params.functionName !== undefined) {
      queryParams[MinisterListFilterQueryParam.FUNCT] = params.functionName;
    }
    if (params.governmentName !== undefined) {
      queryParams[MinisterListFilterQueryParam.GOVERNMENT] =
        params.governmentName;
    }
    if (params.historicalPeriodName !== undefined) {
      queryParams[MinisterListFilterQueryParam.HISTORIC_PERIOD] =
        params.historicalPeriodName;
    }
    queryParams[MinisterListFilterQueryParam.INSTITUTION_NAME] = 
      params.institution?.name;
    queryParams[MinisterListFilterQueryParam.INSTITUTION_BEGINNING] = 
      params.institution?.termBeginning;
    queryParams[MinisterListFilterQueryParam.INSTITUTION_END] = 
      params.institution?.termEnd;

    if (params.isDead !== undefined) {
      queryParams[MinisterListFilterQueryParam.ONLY_DEAD] = params.isDead;
    }
    if (params.isCurrentFunction !== undefined) {
      queryParams[MinisterListFilterQueryParam.ONLY_CURRENT_FUNCTION] =
        params.isCurrentFunction;
    }
    queryParams[MinisterListFilterQueryParam.YEAR_START] =
      params.yearsActive?.start;
    queryParams[MinisterListFilterQueryParam.YEAR_END] =
      params.yearsActive?.end;
    /// filters

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
