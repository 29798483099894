<!-- progress-bar -->
<app-progress-bar></app-progress-bar>

<!-- topmost focusable element -->
<div id="app-top" tabindex="-1" class="sr-only">Początek strony</div>

<!-- header -->
<app-header></app-header>

<!-- main -->
<main id="app-content" tabindex="-1" class="main">
  <router-outlet></router-outlet>
</main>

<!-- footer -->
<app-footer></app-footer>
