<!-- footer -->
<footer class="app-footer" id="app-footer" tabindex="-1">
  <h2 class="sr-only">Stopka</h2>
  <div class="container-layout">
    <ul class="app-footer__boxes">
      <li class="app-footer__box">
        <a [routerLink]="['/']" class="logo">
          Rządy
          <strong class="d-block">Rzeczypospolitej Polskiej</strong>
        </a>
        <p class="app-footer__question">
          Masz pytanie?<span class="d-block">
            <a href="mailto:{{ email }}" class="app-footer__mail">{{
              email
            }}</a>
          </span>
        </p>
        <p>
          Kanceleria Prezesa Rady Ministrów
          <span class="d-block"> Al. Ujazdowskie 1/3 00-583 Warszawa </span>
        </p>
      </li>
      <li class="app-footer__box">
        <h3 class="app-footer__heading">O projekcie</h3>
        <p class="app-footer__description">
          Rządy Rzeczypospolitej Polskiej to projekt upamiętnienia członków Rady
          Ministrów II Rzeczypospolitej, Rządów na Uchodźstwie i III
          Rzeczypospolitej.
        </p>
      </li>
      <li class="app-footer__box">
        <h3 class="app-footer__heading">Nawigacja</h3>
        <nav>
          <ul role="menu">
            <!-- home -->
            <li
              role="presentation"
              class="app-footer__item"
              routerLinkActive="active"
              #menuLinkHome="routerLinkActive"
              [routerLinkActiveOptions]="{ exact: true }">
              <a
                [routerLink]="['/']"
                role="menuitem"
                class="app-footer__link"
                [attr.aria-current]="menuLinkHome.isActive ? true : null">
                Strona główna
              </a>
            </li>

            <!-- about -->
            <li
              role="presentation"
              class="app-footer__item"
              routerLinkActive="active"
              #menuLinkAbout="routerLinkActive">
              <a
                [routerLink]="['/', routeNames.ABOUT]"
                role="menuitem"
                class="app-footer__link"
                [attr.aria-current]="menuLinkAbout.isActive ? true : null">
                O projekcie
              </a>
            </li>

            <!-- members -->
            <li
              role="presentation"
              class="app-footer__item"
              [ngClass]="{
                active: router | isMinisterListRouteActive
              }">
              <a
                [routerLink]="['/', routeNames.MINISTERS]"
                role="menuitem"
                class="app-footer__link"
                [attr.aria-current]="(router | isMinisterListRouteActive) ? true : null">
                Członkowie Rady Ministrów
              </a>
            </li>

            <!-- memorial -->
            <li
              role="presentation"
              class="app-footer__item"
              routerLinkActive="active"
              #menuLinkMemorial="routerLinkActive">
              <a
                [routerLink]="['/', routeNames.MEMORIALS]"
                role="menuitem"
                class="app-footer__link"
                [attr.aria-current]="menuLinkMemorial.isActive ? true : null">
                Mapa miejsc pamięci
              </a>
            </li>

            <!-- contact -->
            <li
              role="presentation"
              class="app-footer__item"
              routerLinkActive="active"
              #menuLinkContact="routerLinkActive">
              <a
                [routerLink]="['/', routeNames.CONTACT]"
                role="menuitem"
                class="app-footer__link"
                [attr.aria-current]="menuLinkContact.isActive ? true : null">
                Kontakt
              </a>
            </li>
          </ul>
        </nav>
      </li>
      <li class="app-footer__box">
        <h3 class="app-footer__heading">Ważne linki</h3>

        <div class="app-footer__important-links">
          <a
            href="https://iderepublica.pl"
            target="_blank"
            rel="noopener"
            class="app-footer__important-link">
            <img
              class="app-footer__important-img"
              src="/assets/img/instytut-de-republica.svg"
              alt="Instytut de Republica logo" />
          </a>

          <a
            href="https://www.gov.pl/web/premier"
            target="_blank"
            rel="noopener"
            class="app-footer__important-link">
            <img
              class="app-footer__important-img"
              src="https://www.gov.pl/img/units/kprm/05_znak_uproszczony_kolor_biale_tlo.png"
              alt="Kanceleria Prezesa Rady Ministrów logo"
              height="45" />
          </a>
        </div>
      </li>
    </ul>
  </div>

  <!-- page bottom -->
  <div class="app-footer__bottom">
    <div class="container-layout">
      <div class="app-footer__bottom-bar">
        &copy; 2023 - Rządy Rzeczypospolitej Polskiej
      </div>
    </div>
  </div>
</footer>
