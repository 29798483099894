import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { GalleryModalViewModel } from './gallery-modal.view-model';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryModalComponent {
  private activeIndex = 0;
  private readonly mobileBreakpoint = 768;
  
  @HostListener('window:resize')
  onResize() {
    if (window.innerWidth < this.mobileBreakpoint) {
      this.onDialogClose();
    }
  }

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: GalleryModalViewModel
  ) {}

  public onDialogClose(): void {
    this.dialogRef.close(this.activeIndex);
  }

  public onImageChange(index: number): void {
    this.activeIndex = index;
  }
}
