<nav
  class="skip-menu"
  [ngStyle]="{ visibility: (isMenuOpened$ | async) ? 'hidden' : 'visible' }">
  <ul>
    <li>
      <!-- menu -->
      <a [routerLink]="[]" class="skip-menu__link" [fragment]="'app-header'">
        Przejdź do menu głównego
      </a>
    </li>
    <li>
      <!-- content -->
      <a [routerLink]="[]" class="skip-menu__link" [fragment]="'app-content'">
        Przejdź do treści
      </a>
    </li>
    <li>
      <!-- footer -->
      <a [routerLink]="[]" class="skip-menu__link" [fragment]="'app-footer'">
        Przejdź do stopki
      </a>
    </li>
  </ul>
</nav>

<header class="app-header" id="app-header" tabindex="-1" [ngStyle]="{ '--height': (height$ | async)! }">
  <div class="container-layout">
    <div class="app-header__inner">
      <!-- logo -->
      <a [routerLink]="['/']" class="logo">
        Rządy
        <strong class="d-block">Rzeczypospolitej Polskiej</strong>
      </a>

      <!-- menu -->
      <nav class="top-menu">
        <!-- menu toggle -->
        <input
          type="checkbox"
          id="top-menu-toggle"
          class="sr-only top-menu__toggle-input"
          aria-haspopup="true"
          aria-controls="top-menu"
          [attr.aria-expanded]="(isMenuOpened$ | async) ? true : false"
          [checked]="(isMenuOpened$ | async) ? true : false"
          (click)="onMenuToggle()"
          (keydown.enter)="onMenuToggle()" />

        <label class="top-menu__toggle" for="top-menu-toggle">
          <span class="top-menu__toggle-icon" aria-hidden="true"></span>
          <span
            class="top-menu__toggle-close"
            [attr.aria-hidden]="(isMenuOpened$ | async) ? null : true"
            >Zamknij</span
          >
          <span
            class="top-menu__toggle-title"
            [ngClass]="{ 'sr-only': (isMenuOpened$ | async) }"
            >Menu</span
          >
        </label>

        <div class="top-menu__wrapper">
          <!-- heading -->
          <p class="heading-4 top-menu__heading" id="top-menu-heading">Menu</p>

          <!-- menu list -->
          <ul
            role="menu"
            class="top-menu__list"
            aria-labelledby="top-menu-heading"
            id="top-menu"
            [attr.aria-hidden]="(isMenuOpened$ | async) ? false : true">
            <!-- home -->
            <li
              role="presentation"
              class="top-menu__item"
              routerLinkActive="active"
              #menuLinkHome="routerLinkActive"
              [routerLinkActiveOptions]="{ exact: true }">
              <a
                [routerLink]="['/']"
                role="menuitem"
                class="top-menu__link"
                [attr.aria-current]="menuLinkHome.isActive ? true : null"
                (click)="onMenuHide()">
                Strona główna
              </a>
            </li>

            <!-- about -->
            <li
              role="presentation"
              class="top-menu__item"
              routerLinkActive="active"
              #menuLinkAbout="routerLinkActive">
              <a
                [routerLink]="['/', routeNames.ABOUT]"
                role="menuitem"
                class="top-menu__link"
                [attr.aria-current]="menuLinkAbout.isActive ? true : null"
                (click)="onMenuHide()">
                O projekcie
              </a>
            </li>

            <!-- governments -->
            <li
              role="presentation"
              class="top-menu__item"
              [ngClass]="{
                active: router | isMinisterListRouteActive
              }">
              <!-- sub-menu -->
              <div
                class="sub-menu"
                [ngClass]="{ 'sub-menu--visible': (isSubMenuOpened$ | async) }">
                <button
                  type="button"
                  class="sub-menu__toggle"
                  aria-haspopup="true"
                  [attr.aria-expanded]="
                    (isSubMenuOpened$ | async) ? true : false
                  "
                  (click)="toggleSubmenu()"
                  (keydown)="onSubMenuKeydown($event, true)">
                  <span class="sub-menu__toggle-text"
                    >Członkowie Rady Ministrów</span
                  >
                  <span
                    class="gov-icon gov-icon--arrow-right-2-center sub-menu__toggle-icon"></span>
                </button>

                <ul *ngIf="historicalPeriods$ | async as historicalPeriods" class="sub-menu__list">
                  <li *ngFor="let historicalPeriod of historicalPeriods" class="sub-menu__item">
                    <a
                      class="sub-menu__link"
                      [routerLink]="['/', routeNames.GOVERNMENTS, historicalPeriod.slug]"
                      (click)="onMenuHide()">
                      {{ historicalPeriod.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <!-- memorial -->
            <li
              role="presentation"
              class="top-menu__item"
              routerLinkActive="active"
              #menuLinkMemorial="routerLinkActive">
              <a
                [routerLink]="['/', routeNames.MEMORIALS]"
                role="menuitem"
                class="top-menu__link"
                [attr.aria-current]="menuLinkMemorial.isActive ? true : null"
                (click)="onMenuHide()">
                Mapa miejsc pamięci
              </a>
            </li>

            <!-- contact -->
            <li
              role="presentation"
              class="top-menu__item"
              routerLinkActive="active"
              #menuLinkContact="routerLinkActive">
              <a
                [routerLink]="['/', routeNames.CONTACT]"
                role="menuitem"
                class="top-menu__link"
                [attr.aria-current]="menuLinkContact.isActive ? true : null"
                (click)="onMenuHide()">
                Kontakt
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
