import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, map, shareReplay, tap } from 'rxjs';
import { MemberQueryModel } from './member.query-model';
import { RouteNames } from 'src/app/config/route-names';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberComponent {
  @ViewChild('biographyHeader') biographyHeader?: ElementRef<HTMLDivElement>;
  @ViewChild('memorialsEl') memorialsEl!: ElementRef<HTMLElement>;
  public routeNames = RouteNames;

  readonly minister$: Observable<MemberQueryModel> = this.route.data.pipe(
    map(data => {
      return data['details'] as MemberQueryModel;
    })
  );

  public readonly bioExcerptChars = 1000;
  private _isBioExcerptVisibleSubject = new BehaviorSubject<boolean>(true);
  public isBioExcerptVisible$ = this._isBioExcerptVisibleSubject
    .asObservable()
    .pipe(
      tap(isVisible => {
        if (this.biographyHeader && isVisible) {
          this.biographyHeader.nativeElement.focus();
        }
      }),
      shareReplay(1)
    );

  constructor(private route: ActivatedRoute) {}

  public goToMemorial() {
    this.memorialsEl?.nativeElement?.scrollIntoView();
  }

  public onBioToggle(): void {
    const currentValue = this._isBioExcerptVisibleSubject.getValue();
    this._isBioExcerptVisibleSubject.next(!currentValue);
  }
}
