import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Inject, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged, shareReplay, take, tap } from 'rxjs';
import { Router } from '@angular/router';
import { RouteNames } from 'src/app/config/route-names';
import { DOCUMENT } from '@angular/common';
import { HistoricalPeriodsService } from 'src/app/services/historical-periods/historical-periods.service';
import { HistoricalPeriod } from 'src/app/interfaces/historical-period.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @ViewChild('menuToggle') menuToggle!: ElementRef;

  public historicalPeriods$: Observable<HistoricalPeriod[]> = this.historicalPeriodsService.getAll();

  private _heightSubject: BehaviorSubject<string> = new BehaviorSubject<string>('100%');
  public height$: Observable<string> = this._heightSubject.asObservable();

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const subMenuEl = (<HTMLElement>(
      this.elementRef.nativeElement
    )).querySelector('.sub-menu') as HTMLElement;
    const isClickInside = subMenuEl.contains(<HTMLElement>event.target);

    if (!isClickInside) {
      this.isSubMenuOpenedSubject.next(false);
    }
  }

  @HostListener('window:resize')
  onResize() {
    this._heightSubject.next(`${this.window.innerHeight}px`);
    // Hide menu if user is resizing window
    // to not block UI
    this.isMenuOpenedSubject.next(false);
  }

  private isMenuOpenedSubject = new BehaviorSubject<boolean>(false);
  public isMenuOpened$ = this.isMenuOpenedSubject.asObservable().pipe(
    distinctUntilChanged(),
    tap(isMenuOpened => {
      if (!isMenuOpened) {
        this.isSubMenuOpenedSubject.next(false);
      }

      this.blockMenuSiblings(isMenuOpened);
    }),
    shareReplay(1)
  );

  private isSubMenuOpenedSubject = new BehaviorSubject<boolean>(false);
  public isSubMenuOpened$ = this.isSubMenuOpenedSubject
    .asObservable()
    .pipe(shareReplay(1));

  public readonly routeNames = RouteNames;

  constructor(
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private window: Window,
    private historicalPeriodsService: HistoricalPeriodsService
  ) {
    this.onResize();
  }

  public onMenuToggle(): void {
    this.isMenuOpened$
      .pipe(
        take(1),
        tap(isMenuOpened => {
          this.isMenuOpenedSubject.next(!isMenuOpened);
        })
      )
      .subscribe();
  }

  public onMenuHide(): void {
    this.isMenuOpenedSubject.next(false);
  }

  public toggleSubmenu(): void {
    this.isSubMenuOpened$
      .pipe(
        take(1),
        tap(isSubMenuOpened => {
          this.isSubMenuOpenedSubject.next(!isSubMenuOpened);
        })
      )
      .subscribe();
  }

  public onSubMenuKeydown(event: KeyboardEvent, isToggle: boolean): void {
    this.isMenuOpened$
      .pipe(
        take(1),
        tap(isMenuOpened => {
          if (isMenuOpened) return;

          if (
            (event.key === 'Tab' && event.shiftKey && isToggle) ||
            (event.key === 'Tab' && !event.shiftKey && !isToggle)
          ) {
            this.isMenuOpenedSubject.next(false);
          }
        })
      )
      .subscribe();
  }

  private blockMenuSiblings(isMenuOpened: boolean): void {
    const body = this.document.body;
    this.renderer.setStyle(body, 'overflow', isMenuOpened ? 'hidden' : 'auto');
    this.renderer.setStyle(
      body.querySelector('#app-content'),
      'visibility',
      isMenuOpened ? 'hidden' : 'visible'
    );
    this.renderer.setStyle(
      body.querySelector('#app-footer'),
      'visibility',
      isMenuOpened ? 'hidden' : 'visible'
    );
  }
}
