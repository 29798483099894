import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { MemorialLocationInterface } from 'src/app/interfaces/memorial-location.interface';
import { MemorialsLocationsService } from 'src/app/services/memorials-map/memorials-map.service';
import { MemorialsFiltersQueryModel } from './memorials-filters.query-model';

export const memorialResolver: ResolveFn<MemorialLocationInterface[]> = (
  activatedRoute: ActivatedRouteSnapshot
) => {
  const queryParamsModel = MemorialsFiltersQueryModel.fromQueryParams(
    activatedRoute.queryParams
  );

  return inject(MemorialsLocationsService).getAllMemorials(queryParamsModel);
};
