import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { MinistersService } from 'src/app/services/ministers/ministers.service';
import { map } from 'rxjs/operators';
import { MemberQueryModel } from './member.query-model';
import { RouteNames } from 'src/app/config/route-names';
import { forkJoin } from 'rxjs';
import { MemorialsLocationsService } from 'src/app/services/memorials-map/memorials-map.service';
import { sortArrByDate } from 'src/app/utils/dates';

export const memberResolver: ResolveFn<MemberQueryModel> = (
  activatedRoute: ActivatedRouteSnapshot
) => {
  const ministersService = inject(MinistersService);
  const memorialsService = inject(MemorialsLocationsService);
  const params = activatedRoute.params;

  if (isNaN(parseInt(params['id']))) {
    inject(Router).navigate(['/', RouteNames.PAGE_NOT_FOUND]);
  }

  // parse slug to get only id
  const id = parseInt(params['id']).toString();

  return forkJoin([
    ministersService.getMinister(id),
    ministersService.getMinisterMemorials(id),
    memorialsService.getMinisterMemorials(id),
  ]).pipe(
    map(([minister, memorials, mapMemorials]) => ({
        minister: {
          ...minister,
          functions: sortArrByDate(minister.functions, {
            dateField: 'periodOfOfficeToDate', 
            isDescending: true
          }),
          id: id,
          isDead: !!minister.dateOfDeath,
          hasMapLocations: memorials.length
            ? memorials.some(item => item.gpsLocation)
            : false,
        },
        memorials,
        mapMemorials,
    }))
  );
};
