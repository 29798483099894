import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { Government } from 'src/app/interfaces/government.interface';
import { sortArrByDate } from 'src/app/utils/dates';

@Injectable({
  providedIn: 'root',
})
export class GovernmentsService {
  constructor(private http: HttpClient) {}

  public getGovernmentByPeriod(
    period: string,
    sortDirection: string
  ): Observable<Government[]> {
    return this.http
      .get<Government[]>('page/governments', {
        params: {
          period,
        },
      })
      .pipe(
        map(governments =>
          governments.map(gov => ({
            ...gov,
            termBeginning: this.changeDateFormat(gov.termBeginning),
            termEnd: this.changeDateFormat(gov.termEnd),
          }))
        ),
        map(governments =>
          sortArrByDate(governments, {
            dateField: 'termBeginning',
            isDescending: sortDirection === 'desc',
          })
        ),
        shareReplay(1)
      );
  }

  private changeDateFormat(date: string | null): string {
    if (!date) {
      return '';
    }

    return new Date(date).toISOString().slice(0, 10);
  }
}
