import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { BehaviorSubject, shareReplay } from 'rxjs';
import { DropdownItem } from 'src/app/interfaces/dropdown-item.interface';

let uniqueId = 0;

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent<T> implements OnChanges {
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const isClickInside = this.getHostElement().contains(
      <HTMLElement>event.target
    );

    if (!isClickInside) {
      this.closeDropdown();
    }
  }
  private isExpandedSubject = new BehaviorSubject(false);
  public isExpanded$ = this.isExpandedSubject
    .asObservable()
    .pipe(shareReplay(1));
  private indexesSubject = new BehaviorSubject({
    selected: -1,
    current: -1,
  });
  public indexes$ = this.indexesSubject.asObservable().pipe(shareReplay(1));

  @Input() expanderLabel = 'Wybierz z listy';
  @Input() items: DropdownItem<T>[] = [];
  @Input() selectedValue: unknown = '';
  @Input() selectedItemAsLabel = false;
  @Input() selectedItemLabel = 'Wybrano:';
  @Input() markSelectedItem = true;
  @Input() showClearButton = false;

  @Output() onDropdownItemClick = new EventEmitter<DropdownItem<T>>();
  @Output() onDropdownClearClick = new EventEmitter<void>();

  public readonly uniqueId = uniqueId++;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(): void {
    this.setIndexesFromSelectedValue();
  }

  public onExpanderClick(): void {
    if (this.isExpandedSubject.getValue()) {
      this.closeDropdown();
    } else {
      this.isExpandedSubject.next(true);
      this.skipToSelectedItem();
    }
  }

  public onExpanderKeydown(event: Event): void {
    if (!(event instanceof KeyboardEvent)) return;

    switch (event.key) {
      case 'Escape':
        this.closeDropdown();
        break;

      case 'ArrowDown':
        if (this.isExpandedSubject.getValue()) {
          this.setNextItemAsCurrent();
          this.setFocusOnItem();
          event.preventDefault();
        } else {
          this.isExpandedSubject.next(true);
          this.skipToSelectedItem();
        }
        break;

      case 'ArrowUp':
        this.setPreviousItemAsCurrent();
        this.setFocusOnItem();
        break;

      case 'Tab':
        if (!this.isExpandedSubject.getValue()) {
          return;
        }

        if (event.shiftKey) {
          this.closeDropdown();
        } else {
          event.preventDefault();
          this.setNextItemAsCurrent();
          this.setFocusOnItem();
        }

        break;
    }
  }

  public onItemClick(): void {
    const indexes = this.indexesSubject.getValue();
    this.indexesSubject.next({
      ...indexes,
      selected: indexes.current,
    });
    this.onDropdownItemClick.emit(this.items[indexes.current]);
    this.closeDropdown();
  }

  public onItemKeydown(event: Event): void {
    if (!(event instanceof KeyboardEvent)) return;

    const indexes = this.indexesSubject.getValue();

    switch (event.key) {
      case 'Enter':
        this.onItemClick();
        this.setFocusOnExapnder();
        event.preventDefault();
        break;

      case 'Escape':
        this.closeDropdown();
        this.setFocusOnExapnder();
        event.preventDefault();
        break;

      case 'ArrowDown':
        this.setNextItemAsCurrent();
        this.setFocusOnItem();
        event.preventDefault();
        break;

      case 'ArrowUp':
        this.setPreviousItemAsCurrent();
        this.setFocusOnItem();
        event.preventDefault();
        break;

      case 'Tab':
        if (event.shiftKey && indexes.current < 0) {
          this.indexesSubject.next({
            ...indexes,
            current: -1,
          });
        } else if (event.shiftKey && indexes.current >= 0) {
          this.setPreviousItemAsCurrent();
        } else if (
          !event.shiftKey &&
          indexes.current === this.items.length - 1
        ) {
          this.closeDropdown();
        } else if (!event.shiftKey && indexes.current < this.items.length - 1) {
          this.setNextItemAsCurrent();
        }
        break;
    }
  }

  public onClearClick(): void {
    this.onDropdownClearClick.emit();
  }

  private setNextItemAsCurrent(): void {
    const indexes = this.indexesSubject.getValue();
    if (indexes.current !== this.items.length - 1) {
      this.indexesSubject.next({
        ...indexes,
        current: indexes.current + 1,
      });
    }
  }

  private setPreviousItemAsCurrent(): void {
    const indexes = this.indexesSubject.getValue();
    if (indexes.current > 0) {
      this.indexesSubject.next({
        ...indexes,
        current: indexes.current - 1,
      });
    }
  }

  private setFocusOnItem(): void {
    const indexes = this.indexesSubject.getValue();
    const item = this.getHostElement().querySelectorAll('.dropdown__item')[
      indexes.current
    ] as HTMLElement;

    if (item) {
      item.focus();
    }
  }

  private setFocusOnExapnder(): void {
    (<HTMLElement>(
      this.getHostElement().querySelector('.dropdown__expander')
    )).focus();
  }

  private setIndexesFromSelectedValue(): void {
    const valueIndex = this.items.findIndex(
      item => JSON.stringify(item.value) === JSON.stringify(this.selectedValue)
    );
    this.indexesSubject.next({
      current: valueIndex,
      selected: valueIndex,
    });
  }

  private skipToSelectedItem(): void {
    if (this.markSelectedItem && !!this.selectedValue) {
      setTimeout(() => {
        this.setIndexesFromSelectedValue();
        this.setFocusOnItem();
      });
    }
  }

  private getHostElement(): HTMLElement {
    return <HTMLElement>this.elementRef.nativeElement;
  }

  private closeDropdown(): void {
    this.isExpandedSubject.next(false);
    const indexes = this.indexesSubject.getValue();
    this.indexesSubject.next({
      ...indexes,
      current: -1,
    });
  }

  setCurrentIndex(index: number): void {
    const indexes = this.indexesSubject.getValue();
    this.indexesSubject.next({
      ...indexes,
      current: index,
    });
  }
}
