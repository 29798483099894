import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { MinistersService } from './ministers/ministers.service';

export const ministerNameResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot
) => {
  const id = parseInt(route.paramMap.get('id')!);

  if (isNaN(id)) return of('Szczegóły ministra');

  return inject(MinistersService)
    .getMinister(id.toString())
    .pipe(
      map(
        minister =>
          `${minister.firstName} ${minister.secondName ?? ''} ${
            minister.lastName
          }`
      ),
      catchError(_ => of('Szczegóły ministra'))
    );
};
