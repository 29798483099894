<div class="pagination" id="pagination-{{ uniqueId }}" *ngIf="{ value: (currentPage$ | async)! } as currentPage">
  <!-- prev -->
  <button
    type="button"
    class="btn btn--plain pagination__arrow"
    (click)="onPageChange(currentPage.value - 1)"
    [ngClass]="{ inactive: currentPage.value <= 1 }"
    [attr.tabindex]="currentPage.value <= 1 ? -1 : null"
    [attr.aria-hidden]="currentPage.value <= 1 ? true : null">
    <span class="sr-only"> Przejdź do poprzedniej strony </span>
    <span
      class="gov-icon gov-icon--arrow-left-thin pagination__icon"
      aria-hidden="true"></span>
  </button>

  <!-- input -->
  <span class="pagination__input-wrapper">
    <label for="pagination-input-{{ uniqueId }}" class="sr-only"
      >Wprowadź numer strony</label
    >
    <input
      type="text"
      class="form-control pagination__input"
      id="pagination-input-{{ uniqueId }}"
      [attr.maxlength]="totalPages.toString().length"
      autocomplete="off"
      [value]="currentPage.value"
      (keydown)="onKeyDown($event)"
      attr.aria-describedby="pagination-total-{{ uniqueId }}" />

      <span class="sr-only" id="pagination-total-{{ uniqueId }}">
        Strona {{ currentPage.value }} z {{ totalPages }}
      </span>
  </span>

  <!-- of -->
  <span class="pagination__total-of" aria-hidden="true">z</span>
    
  <!-- last -->
  <button
    type="button"
    class="btn btn--plain pagination__last"
    (click)="onPageChange(totalPages)">
      <span class="sr-only">Przejdź do ostatniej strony</span>
      <span aria-hidden="true">{{ totalPages }}</span>
  </button>

  <!-- next -->
  <button
    type="button"
    class="btn btn--plain pagination__arrow"
    (click)="onPageChange(currentPage.value + 1)"
    [ngClass]="{ inactive: currentPage.value >= totalPages }"
    [attr.tabindex]="currentPage.value >= totalPages ? -1 : null"
    [attr.aria-hidden]="currentPage.value >= totalPages ? true : null">
    <span class="sr-only"> Przejdź do kolejnej strony </span>
    <span
      class="gov-icon gov-icon--arrow-right-thin pagination__icon"
      aria-hidden="true"></span>
  </button>
</div>
