<form class="search" (ngSubmit)="onSearch()" autocomplete="off" role="search">
  <!-- description -->
  <p
    class="search__description"
    [ngClass]="{ 'search__description--m': isAdvancedSearch }">
    <ng-content select="[description]"></ng-content>
  </p>

  <!-- search -->
  <div class="search__inner">
    <label for="search-input" class="sr-only">Wpisz imię, nazwisko</label>
    <input
      type="text"
      id="search-input"
      class="search__input"
      placeholder="Wpisz imię, nazwisko"
      autocomplete="off"
      [minlength]="minChars"
      [maxlength]="maxChars"
      #query
      [formControl]="searchTextControl"
      (focusin)="onFocusIn()"
      [attr.aria-invalid]="(isInputInvalid$ | async) ? true : null"
      [attr.aria-describedby]="
        (isInputInvalid$ | async) ? 'search-error' : null
      "
      [ngClass]="{ invalid: (isInputInvalid$ | async) }" />

    <!-- clear input -->
    <button
      type="button"
      class="btn btn--plain search__clear"
      (click)="onInputClear()"
      *ngIf="searchTextControl.value">
      <img
        src="/assets/img/svg/close.svg"
        alt="Wyczyść pole wyszukiwania"
        class="search__clear-img" />
    </button>

    <!-- submit -->
    <button type="submit" class="btn btn--primary search__button">
      Wyszukaj
    </button>

    <!-- error message -->
    <p
      class="search__error"
      role="alert"
      id="search-error"
      *ngIf="isInputInvalid$ | async">
      Aby poprawnie wyszukać wyniki, wpisz przynajmniej {{ minChars }} znaki
    </p>
  </div>

  <!-- advanced -->
  <a
    class="search__link"
    [routerLink]="[searchResultsUrl]"
    *ngIf="!isAdvancedSearch">
    Zobacz wszystkich ministrów
  </a>
</form>
