import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Image } from 'src/app/interfaces/image.interface';
import { FormDialogsService } from 'src/app/services/form-dialogs.service';
import { GalleryModalComponent } from '../gallery-modal/gallery-modal.component';
import { GalleryModalViewModel } from '../gallery-modal/gallery-modal.view-model';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent {
  private readonly mobileBreakpoint = 768;
  @Input() images: Image[] = [];

  private _activeIndexSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public readonly activeIndex$: Observable<number> =
    this._activeIndexSubject.asObservable();

  constructor(private _formDialogsService: FormDialogsService) {}

  public selectImage(index: number): void {
    this._activeIndexSubject.next(index);
  }

  public showModal(index: number): void {
    if (window.innerWidth < this.mobileBreakpoint) return;

    const data: GalleryModalViewModel = {
      activeIndex: index,
      images: this.images,
    };

    this._formDialogsService
      .openDialog<GalleryModalComponent, GalleryModalViewModel, number>(
        GalleryModalComponent,
        data
      )
      .subscribe();
  }
}
