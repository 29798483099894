<div class="year-range-picker" *ngIf="value$ | async as value">
  <button
    class="year-range-picker__label"
    [ngClass]="{
      'year-range-picker__label--filled': isFilled$ | async
    }"
    (click)="toggle()">
    {{ valueLabel$ | async }}
  </button>

  <button
    class="year-range-picker__clear"
    (click)="onClearClick()"
    *ngIf="isFilled$ | async">
    <span class="sr-only"> Wyczyść pole wyboru </span>
  </button>

  <div class="year-range-picker__preview" *ngIf="isVisible$ | async">
    <p class="year-range-picker__preview-header">Wybierz zakres</p>

    <div class="year-range-picker__preview-values">
      <div class="year-range-picker__preview-value">
        <input
          type="text"
          class="year-range-picker__preview-control"
          [formControl]="yearFromControl"
          placeholder="od"
          autocomplete="off"
          [attr.aria-label]="
            value.start !== undefined
              ? 'Zmień rok od, ' + value.start
              : 'Wybierz rok od'
          "
          (click)="toggleFrom()"
          (keydown)="onFromControlKeydown($event)"
          (change)="onFromChange()"
          maxlength="4" />
        <app-year-picker
          #pickerFrom
          [value]="value.start"
          [max]="value.end"
          (onValueClick)="setFrom($event)"></app-year-picker>
      </div>
      -
      <div class="year-range-picker__preview-value">
        <input
          type="text"
          class="year-range-picker__preview-control"
          [formControl]="yearToControl"
          placeholder="od"
          autocomplete="off"
          [attr.aria-label]="
            value.start !== undefined
              ? 'Zmień rok do, ' + value.start
              : 'Wybierz rok do'
          "
          (click)="toggleTo()"
          (keydown)="onToControlKeydown($event)"
          (change)="onToChange()"
          maxlength="4" />
        <app-year-picker
          #pickerTo
          [value]="value.end"
          [min]="value.start"
          (onValueClick)="setTo($event)"
          (onForceClose)="close()"></app-year-picker>
      </div>
    </div>
  </div>
</div>
