export enum RouteNames {
  ABOUT = 'o-projekcie',
  CONTACT = 'kontakt',
  GOVERNMENTS = 'rzady',
  GOVERNMENTS_DETAILS = 'rzady/:slug',
  GOVERNMENTS_II_RP = 'ii-rzeczpospolita',
  GOVERNMENTS_III_RP = 'iii-rzeczpospolita',
  GOVERNMENTS_NU = 'rzad-na-uchodzstwie',
  MEMORIALS = 'miejsca-pamieci',
  MINISTERS = 'lista-ministrow',
  PAGE_NOT_FOUND = '404',
  SERVER_ERROR = '500'
}
