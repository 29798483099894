<!-- hero / search -->
<section class="home__hero" id="hero">
  <div class="container-layout">
    <h1 class="heading-1 home__hero-heading">
      <em class="home__hero-heading-sup d-block"
        >Rządy Rzeczypospolitej Polskiej</em
      >
      Poznaj ministrów
      <em class="d-block home__hero-heading-highlight"
        >Rzeczypospolitej Polskiej</em
      >
    </h1>

    <!-- search form -->
    <app-search>
      <ng-container description>
        Dowiedz się więcej o ministrach Rzeczypospolitej Polskiej.
        <span class="block-desktop">
          Skorzystaj z wyszukiwarki, aby znaleźć konkretnych ministrów.
        </span>
      </ng-container>
    </app-search>
  </div>

  <div class="home__hero-ministry">
    <img
      class="grayscale"
      height="64px"
      src="/assets/img/instytut-de-republica.svg"
      alt="Instytut de Republica logo" />
    <img
      class="grayscale"
      height="64px"
      src="https://www.gov.pl/img/units/kprm/05_znak_uproszczony_kolor_biale_tlo.png"
      alt="Kanceleria Prezesa Rady Ministrów logo" />
  </div>
</section>

<!-- profiles / carousel -->
<!-- <section class="home-profiles" id="profiles">
  <div class="container-content">
    <h2 class="heading-2 home-profiles__heading">
      Ministrowie Rzeczypospolitej Polskiej
    </h2>
    <p class="home-profiles__description">
      Najbardziej znani ministrowie rządu Rzeczypospolitej Polskiej.
    </p> -->

<!-- carousel -->
<!-- <app-carousel></app-carousel>
  </div>
</section> -->

<!-- memorial map -->
<div class="container-content" id="memorial-map">
  <section class="linked linked--bg-1">
    <h2 class="heading-2 linked__heading">
      Miejsca pamięci ministrów
      <span class="d-block"> Rzeczypospolitej Polskiej </span>
    </h2>
    <p class="linked__description">Znajdź miejsce pamięci na mapie.</p>
    <a
      [routerLink]="['/', routeNames.MEMORIALS]"
      class="link link--l link--btn-primary">
      Zobacz mapę
    </a>
  </section>
</div>

<!-- governments -->
<section class="home-governments" id="governments">
  <h2 class="heading-2 home-governments__heading">
    Podział rządów
    <span class="d-block"> Rzeczypospolitej Polskiej </span>
  </h2>
  <p class="home-governments__description">
    Dowiedz się więcej o ministrach w podziale na okres historyczny rządów
    Rzeczypospolitej Polskiej.
  </p>
  <ul class="home-governments__list">
    <li class="home-governments__item">
      <img
        src="/assets/img/svg/herb-II-rp.svg"
        class="home-governments__image"
        alt="II Rzeczpospolita - Herb Polski" />
      <p class="home-governments__title">II Rzeczpospolita</p>
      <p class="home-governments__date">(1918 - 1939)</p>
      <a
        class="link link--l"
        [routerLink]="[
          '/',
          routeNames.GOVERNMENTS,
          routeNames.GOVERNMENTS_II_RP
        ]">
        Zobacz szczegóły
      </a>
    </li>
    <li class="home-governments__item">
      <img
        src="/assets/img/svg/herb-na-uchodzstwie.svg"
        class="home-governments__image"
        alt="Rząd na Uchodźstwie - Herb Polski" />
      <p class="home-governments__title">Rząd na Uchodźstwie</p>
      <p class="home-governments__date">(1939 - 1990)</p>
      <a
        class="link link--l"
        [routerLink]="['/', routeNames.GOVERNMENTS, routeNames.GOVERNMENTS_NU]">
        Zobacz szczegóły
      </a>
    </li>
    <li class="home-governments__item">
      <img
        src="/assets/img/svg/herb-III-rp.svg"
        class="home-governments__image"
        alt="III Rzeczpospolita - Herb Polski" />
      <p class="home-governments__title">III Rzeczpospolita</p>
      <p class="home-governments__date">(1989 - obecnie)</p>
      <a
        class="link link--l"
        [routerLink]="[
          '/',
          routeNames.GOVERNMENTS,
          routeNames.GOVERNMENTS_III_RP
        ]">
        Zobacz szczegóły
      </a>
    </li>
  </ul>
</section>

<!-- about -->
<div class="home-about" id="about">
  <div class="container-content">
    <section class="linked">
      <h2 class="heading-2 linked__heading">O projekcie</h2>
      <p class="linked__description linked__description--alt">
        Rządy Rzeczypospolitej Polskiej to projekt upamiętnienia członków Rady
        <span class="linked__description-part">
          Ministrów II Rzeczypospolitej, Rządów na Uchodźstwie i III
          Rzeczypospolitej.
        </span>
      </p>
      <a [routerLink]="['/', routeNames.ABOUT]" class="link link--l link--btn">
        Przeczytaj więcej
        <span class="sr-only">o projekcie</span>
      </a>
    </section>
  </div>
</div>

<!-- write to us -->
<div class="container-content" id="write-to-us">
  <section class="linked linked--bg-2">
    <h2 class="heading-2 linked__heading">
      Masz ciekawe informacje?
      <span class="linked__heading-part"> Napisz do nas </span>
    </h2>
    <p class="linked__description linked__description--alt">
      Skontaktuj się z nami, jeśli masz informacje,
      <span class="linked__description-part"> których nie ma na stronie. </span>
    </p>
    <a [routerLink]="['/', routeNames.CONTACT]" class="link link--l link--btn">
      Napisz do nas wiadomość
    </a>
  </section>
</div>
