import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, map, shareReplay } from 'rxjs';
import {
  MinisterListFilters,
  MinisterListFilter,
  MinisterListFiltersYearsActive,
  MinisterListFilterType,
  MinisterListFiltersInstitution,
} from 'src/app/core/filters';
import { DropdownItem } from 'src/app/interfaces/dropdown-item.interface';
import { DictionaryService } from 'src/app/services/dictionary.service';
import { convertDateStringDashToDot } from 'src/app/utils/dates';

@Component({
  selector: 'app-minister-list-filters',
  templateUrl: './minister-list-filters.component.html',
  styleUrls: ['./minister-list-filters.component.scss'],
})
export class MinisterListFiltersComponent {
  @Output() onFiltersChange = new EventEmitter<MinisterListFilters>();

  private filtersSubject = new BehaviorSubject<MinisterListFilters | undefined>(
    undefined
  );
  public filters$ = this.filtersSubject.asObservable();

  @Input()
  set filters(value: MinisterListFilters) {
    this.filtersSubject.next(value);
    this.countActiveFilters();
  }

  public readonly ministerListFilterType = MinisterListFilterType;
  public readonly expanderDefaultLabel = 'Wszystkie';
  public isOpen$ = new BehaviorSubject<boolean>(false);
  private activeFiltersCounterSubject = new BehaviorSubject<number>(0);
  public activeFiltersCounter$ = this.activeFiltersCounterSubject
    .asObservable()
    .pipe(shareReplay(1));
  public isClearAllBtnVisible$ = combineLatest([
    this.isOpen$,
    this.activeFiltersCounter$,
  ]).pipe(
    map(([isOpen, activeFiltersCounter]) => isOpen && activeFiltersCounter > 0)
  );

  public dictionaries$ = this.dictionaryService.getDictionaries().pipe(
    map(dictionaries => ({
      departments: dictionaries.departments.map(
        item =>
          ({
            label: `${item.name} (${convertDateStringDashToDot(
              item.termBeginning
            )} - ${
              item.termEnd ? convertDateStringDashToDot(item.termEnd) : 'teraz'
            })`,
            value: {
              name: item.name,
              termBeginning: item.termBeginning,
              termEnd: item.termEnd ? item.termEnd : undefined,
            },
          } as DropdownItem<MinisterListFiltersInstitution>)
      ),
      functions: this.convertDictionaryToDropdownItem(
        dictionaries.functionNames
      ),
      governments: dictionaries.governments.map(
        item =>
          ({
            label: `${item.name} (${convertDateStringDashToDot(
              item.termBeginning
            )} - ${
              item.termEnd ? convertDateStringDashToDot(item.termEnd) : 'teraz'
            })`,
            value: item.name,
          } as DropdownItem)
      ),
      historicPeriods: this.convertDictionaryToDropdownItem(
        dictionaries.historicalPeriodNames
      ),
    })),
    shareReplay(1)
  );

  constructor(public dictionaryService: DictionaryService) {}

  public onFilterValueChange(filter: MinisterListFilter): void {
    const currentFilters = this.filtersSubject.getValue();

    if (currentFilters === undefined) return;

    const newFilters = Object.keys(currentFilters).reduce(
      (a: MinisterListFilters, prop: string) => {
        const filterName = prop as keyof MinisterListFilters;
        const filterType = typeof currentFilters[filterName];

        if (prop === filter.name && filterType === 'string') {
          return {
            ...a,
            [filterName]: filter.value as string,
          };
        }
        if (
          prop === filter.name &&
          filterType === 'boolean' &&
          filterName === MinisterListFilterType.ONLY_DEAD
        ) {
          return {
            ...a,
            [filterName]: filter.value as boolean,
            [MinisterListFilterType.ONLY_CURRENT_FUNCTION]: false,
          };
        }
        if (
          prop === filter.name &&
          filterType === 'boolean' &&
          filterName === MinisterListFilterType.ONLY_CURRENT_FUNCTION
        ) {
          return {
            ...a,
            [filterName]: filter.value as boolean,
            [MinisterListFilterType.ONLY_DEAD]: false,
          };
        }
        if (
          prop === filter.name &&
          filterName === MinisterListFilterType.YEARS_ACTIVE
        ) {
          return {
            ...a,
            [filterName]: filter.value as MinisterListFiltersYearsActive,
          };
        }
        if (
          prop === filter.name &&
          filterName === MinisterListFilterType.INSTITUTION
        ) {
          return {
            ...a,
            [filterName]: filter.value as MinisterListFiltersInstitution,
          };
        }

        return {
          [filterName]: currentFilters[filterName],
          ...a,
        };
      },
      {} as MinisterListFilters
    );

    this.filtersSubject.next(newFilters);

    this.countActiveFilters();
    this.onFiltersChange.emit(newFilters);
  }

  public onClearAll(): void {
    const currentFilters = this.filtersSubject.getValue();

    if (currentFilters === undefined) return;

    const newFilters = Object.keys(currentFilters).reduce(
      (a: MinisterListFilters, prop: string) => {
        const filterName = prop as keyof MinisterListFilters;
        const filterType = typeof currentFilters[filterName];

        if (filterType === 'string') {
          return {
            ...a,
            [filterName]: '',
          };
        }
        if (filterType === 'boolean') {
          return {
            ...a,
            [filterName]: false,
          };
        }
        if (filterName === MinisterListFilterType.YEARS_ACTIVE) {
          return {
            ...a,
            [filterName]: { start: undefined, end: undefined },
          };
        }
        if (filterName === MinisterListFilterType.INSTITUTION) {
          return {
            ...a,
            [filterName]: { name: '', termBeginning: '', termEnd: undefined },
          };
        }

        return {
          ...a,
          [filterName]: currentFilters[filterName],
        };
      },
      {} as MinisterListFilters
    );

    this.filtersSubject.next(newFilters);

    this.countActiveFilters();
    this.onFiltersChange.emit(newFilters);
  }

  public onToggle(): void {
    const isOpen = this.isOpen$.getValue();
    this.isOpen$.next(!isOpen);
  }

  private convertDictionaryToDropdownItem(
    dictionary: string[]
  ): DropdownItem[] {
    return dictionary.map(item => ({
      label: item,
      value: item,
    }));
  }

  private countActiveFilters(): void {
    const currentFilters = this.filtersSubject.getValue();

    if (currentFilters === undefined) return;

    const counter = Object.keys(currentFilters).reduce((a, prop) => {
      const filterName = prop as keyof MinisterListFilters;
      const filterType = typeof currentFilters[filterName];

      if (
        filterType === 'string' &&
        (currentFilters[filterName] as string) !== ''
      ) {
        return a + 1;
      }
      if (
        filterType === 'boolean' &&
        (currentFilters[filterName] as boolean) !== false
      ) {
        return a + 1;
      }
      if (
        filterName === MinisterListFilterType.INSTITUTION &&
        currentFilters.institution.name &&
        currentFilters.institution.termBeginning
      ) {
        return a + 1;
      }
      if (
        filterName === MinisterListFilterType.YEARS_ACTIVE &&
        currentFilters.yearsActive.start !== undefined &&
        currentFilters.yearsActive.end !== undefined
      ) {
        return a + 1;
      }

      return a;
    }, 0);

    this.activeFiltersCounterSubject.next(counter);
  }
}
