<article class="container-content http-error http-error--404">
        
    <!-- page heading -->
    <h1 class="heading-2 page-heading http-error__heading">Strony nie znaleziono</h1>

    <!-- description -->
    <p class="http-error__description">
        Niestety, nie znaleźliśmy tej strony.
        <span class="http-error__description-part">
            Przejdż do strony głównej lub spróbuj ponownie.
        </span>
    </p>

    <a class="link link--l link--btn-primary" [routerLink]="['/']">
        Przejdź do strony głównej
    </a>
</article>