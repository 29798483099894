import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../config/tokens';
import { AppConfig } from '../interfaces/app.interface';

@Injectable({
  providedIn: 'root',
})
export class UrlInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

  intercept<T, K>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<K>> {
    const apiReq = req.clone({ url: this.config.apiUrl + req.url });
    return next.handle(apiReq);
  }
}
