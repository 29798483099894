import { Params } from '@angular/router';
import {
  MinisterListFilterQueryParam,
  MinisterListFilters,
} from '../model/minister-list-filters';

export const convertQueryParamsToFilters = (
  qParams: Params
): MinisterListFilters => {
  return {
    functionName: qParams[MinisterListFilterQueryParam.FUNCT] || '',
    governmentName: qParams[MinisterListFilterQueryParam.GOVERNMENT] || '',
    historicalPeriodName:
      qParams[MinisterListFilterQueryParam.HISTORIC_PERIOD] || '',
    institution: {
      name: qParams[MinisterListFilterQueryParam.INSTITUTION_NAME],
      termBeginning: qParams[MinisterListFilterQueryParam.INSTITUTION_BEGINNING],
      termEnd: qParams[MinisterListFilterQueryParam.INSTITUTION_END],
    },
    isDead:
      (qParams[MinisterListFilterQueryParam.ONLY_DEAD] &&
      qParams[MinisterListFilterQueryParam.ONLY_DEAD].toLowerCase() === 'true'
        ? true
        : false) || false,
    isCurrentFunction:
      (qParams[MinisterListFilterQueryParam.ONLY_CURRENT_FUNCTION] &&
      qParams[
        MinisterListFilterQueryParam.ONLY_CURRENT_FUNCTION
      ].toLowerCase() === 'true'
        ? true
        : false) || false,
    yearsActive: {
      start: qParams[MinisterListFilterQueryParam.YEAR_START],
      end: qParams[MinisterListFilterQueryParam.YEAR_END],
    },
  };
};
