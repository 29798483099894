<div class="container-content">
  <div class="about">
    <!-- breadcrumbs -->
    <app-breadcrumbs></app-breadcrumbs>

    <!-- page heading -->
    <h1 class="heading-2 page-heading about__heading-1">Opis projektu</h1>

    <p class="about__intro">
      Rządy Rzeczypospolitej Polskiej to projekt upamiętnienia członków Rady
      Ministrów II Rzeczypospolitej, Rządów na Uchodźstwie i III
      Rzeczypospolitej.
    </p>

    <p class="about__description">
      W ramach projektu powstaje szczegółowy wykaz wszystkich osób będących
      członkami Rady Ministrów, biogramy ministrów II Rzeczypospolitej i Rządów
      na Uchodźstwie, a także wykaz grobów i innych miejsc pamięci po członkach
      Rady Ministrów II Rzeczypospolitej, Rządów na Uchodźstwie i III
      Rzeczypospolitej.
    </p>

    <p class="about__description">
      Potrzeba podjęcia się realizacji projektu wynika z niedostatecznej
      świadomości społecznej i braku zweryfikowanej, uporządkowanej wiedzy na
      temat Rządów Rzeczypospolitej Polskiej.
    </p>

    <p class="about__description">
      Wielu członków Rządów pozostaje postaciami nieznanymi, a informacje
      biograficzne, często bardzo podstawowe pozostają trudno dostępne.
    </p>

    <p class="about__description">
      Wszystkie osoby wchodzące w skład Rady Ministrów II Rzeczypospolitej i
      Rządów na Uchodźstwie już nie żyją, a jednocześnie lokalizacje miejsc
      pochówku nie każdego z nich są znane, co utrudnia sprawowanie należytej
      opieki.
    </p>

    <p class="about__description">
      Stąd też konieczne jest, aby Państwo Polskie zadbało o pamięć i należyte
      upamiętnienie osób, które mu służyły i dlań pracowały, sprawując
      najważniejsze funkcje.
    </p>

    <p class="about__description">
      We współpracy z Instytutem de Republica przygotowywane są słowniki
      biograficzne premierów i ministrów wolnej Rzeczypospolitej.
    </p>

    <p class="about__description">
      W 2023 r. nakładem Instytutu de Republica ukazał się pierwszy tom:
      „Premierzy i ministrowie Rzeczypospolitej Polskiej 1918-1939” pod red.
      Jarosława Kłaczkowa, Zbigniewa Girzyńskiego i Wojciecha Piaska. Biogramy i
      zdjęcia pochodzące z publikacji zostały zamieszczone na stronie
      internetowej PolskieRzady.Gov.PL.
    </p>

    <p class="about__description">
      Treść publikacji jest dostępna na stronie internetowej Biblioteki
      Narodowej w tzw. „wolnym dostępie”:
      <a
        href="https://polona.pl/preview/75c383e2-0e8f-45f9-afe9-e8d40965feec"
        target="_blank"
        rel="noopener"
        >https://polona.pl/preview/75c383e2-0e8f-45f9-afe9-e8d40965feec</a
      >
    </p>

    <p class="about__description">
      W przygotowaniu jest kolejny tom poświęcony Rządom RP na Uchodźstwie. Po
      jego wydaniu, strona zostanie uzupełniona o dodatkowe informacje i
      materiał zdjęciowy.
    </p>

    <p class="about__description">
      Informacje o lokalizacji miejsc pamięci po zmarłych ministrach zostały
      zebrane przez pracowników Kancelarii Prezesa Rady Ministrów we współpracy
      z licznymi partnerami instytucjonalnymi i społecznymi.
    </p>

    <!-- Cele projektu -->
    <h2 class="heading-3 about__heading-2">Cele projektu</h2>

    <div class="about__boxes about__boxes--goal">
      <!-- Upowszechnienie wiedzy -->
      <div class="about__box">
        <img
          src="/assets/img/about/ico-knowledge-bulb.svg"
          alt="Upowszechnienie wiedzy" />

        <h3 class="about__box-heading">Upowszechnienie wiedzy</h3>

        <p class="about__box-text">
          Zebranie i upowszechnienie wiedzy o członkach Rządów Rzeczypospolitej
          Polskiej, pełniących te funkcje w przeszłości - w tym o lokalizacji
          miejsc pochówku zmarłych spośród nich.
        </p>
      </div>

      <!-- Ciągłość -->
      <div class="about__box">
        <img src="/assets/img/about/ico-chart.svg" alt="Ciągłość" />

        <h3 class="about__box-heading">Ciągłość</h3>

        <p class="about__box-text">
          Podkreślenie ciągłości Państwa Polskiego od 1918 r.
        </p>
      </div>

      <!-- Opieka -->
      <div class="about__box">
        <img src="/assets/img/about/ico-care.svg" alt="Opieka" />

        <h3 class="about__box-heading">Opieka</h3>

        <p class="about__box-text">
          Objęcie opieką Państwa i Polaków miejsc pochówku członków Rządów
          Rzeczypospolitej Polskiej, znajdujących się w Polsce i za granicą.
        </p>
      </div>
    </div>

    <!-- Zakres projektu -->
    <h2 class="heading-3 about__heading-2">Zakres projektu</h2>

    <div class="about__boxes about__boxes--range">
      <!-- Listy członków Rady Ministrów -->
      <div class="about__box">
        <img
          src="/assets/img/about/ico-document.svg"
          alt="Listy członków Rady Ministrów" />

        <h3 class="about__box-heading">Listy członków Rady Ministrów</h3>

        <p class="about__box-text">
          Opracowanie listy członków Rady Ministrów II Rzeczypospolitej
          (1918-1939), Rządów na Uchodźstwie (1939-1990) oraz III
          Rzeczypospolitej (1989-obecnie).
        </p>
      </div>

      <!-- Biogramy członków Rady Ministrów -->
      <div class="about__box">
        <img
          src="/assets/img/about/ico-member.svg"
          alt="Biogramy członków Rady Ministrów" />

        <h3 class="about__box-heading">Biogramy członków Rady Ministrów</h3>

        <p class="about__box-text">
          Opracowanie podstawowych biogramów członków Rady Ministrów II
          Rzeczypospolitej (1918-1939) i Rządów na Uchodźstwie (1939-1990) i
          opublikowanie na stronie projektu na zasadach licencji Creative
          Commons.
        </p>
      </div>

      <!-- Słowniki biograficzne Rady Ministrów -->
      <div class="about__box">
        <img
          src="/assets/img/about/ico-board.svg"
          alt="Słowniki biograficzne Rady Ministrów" />

        <h3 class="about__box-heading">Słowniki biograficzne Rady Ministrów</h3>

        <p class="about__box-text">
          Wydanie słowników biograficznych członków Rady Ministrów II
          Rzeczypospolitej (1918-1939) i Rządów na Uchodźstwie (1939-1990),
          zwierających opracowane biogramy.
        </p>
      </div>

      <!-- Lista grobów -->
      <div class="about__box">
        <img src="/assets/img/about/ico-grave.svg" alt="Lista grobów" />

        <h3 class="about__box-heading">Lista grobów i innych miejsc pamięci</h3>

        <p class="about__box-text">
          Opracowanie listy grobów i innych miejsc pamięci po zmarłych członkach
          Rządów Rzeczypospolitej Polskiej, wraz z ich lokalizacjami.
        </p>
      </div>
    </div>

    <p class="about__realizedby">
      Projekt realizowany jest przez Kancelarię Prezesa Rady Ministrów,
      Pełnomocnika Prezesa Rady Ministrów do spraw ochrony miejsc pamięci oraz
      Instytut de Republica.
    </p>

    <!-- historic photo -->
    <div class="about__historic">
      <div class="about__historic-inner">
        <img
          src="/assets//img/about/img-board.png"
          alt="Gabinet Rady Ministrów w rządzie Jędrzeja Moraczewskiego"
          class="about__historic-img" />
      </div>
      <p class="about__historic-bottom">
        Gabinet Rady Ministrów w rządzie Jędrzeja Moraczewskiego po zmianach 29
        grudnia 1918 r.
      </p>
    </div>

    <p class="about__intro">
      Obok projektu Rządy Rzeczypospolitej Polskiej od 2021 r. z inicjatywy
      Prezesa Rady Ministrów, Mateusza Morawieckiego, szereg organizacji
      pozarządowych podjęło się zadań renowacji grobów ministrów II
      Rzeczypospolitej i Rządów na Uchodźstwie.
    </p>

    <p class="about__description">
      Tylko w latach 2021-2022 ze środków Kancelarii Prezesa Rady Ministrów
      sfinansowane prace przy ok. 75 grobach - we współpracy z: Fundacją Stare
      Powązki, Fundacją Dziedzictwa Kulturowego, Fundacją Pomoc Polakom na
      Wschodzie, Fundacją Pro Universitatis, Fundacją Armenian Foundation,
      Fundacją Pomorska Inicjatywa Historyczna, Fundacją Nie zapomnij o nas,
      Stowarzyszeniem Ochrony Dziedzictwa Narodowego w Kielcach.
    </p>

    <!-- historic photo -->
    <div class="about__historic">
      <div class="about__historic-inner">
        <img
          src="/assets//img/about/img-graves.png"
          alt="Grób Witolda Chodźko"
          class="about__historic-img" />
      </div>
      <p class="about__historic-bottom">
        Grób Witolda Chodźko (1875-1954), Ministra Zdrowia Publicznego (10 III
        1922 - 26 V 1923) na Starych Powązkach w Warszawie; poddany renowacji w
        2022 r. przez Fundację Stare Powązki.
      </p>
    </div>
  </div>
</div>
