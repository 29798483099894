import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  shareReplay,
  Subject,
  take,
  tap,
} from 'rxjs';
import { RouteNames } from 'src/app/config/route-names';
import { MinistersService } from 'src/app/services/ministers/ministers.service';
import { CarouselOptionsQueryModel } from './carousel-options.query-model';
import { CarouselDataQueryModel } from './carousel-data.query-model';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterViewInit {
  @ViewChild('carouselList') carouselList!: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const innerWidth = (<Window>event.target).innerWidth;
    this.resizeSubject.next(innerWidth);
  }

  public readonly routeNames = RouteNames;
  private readonly imgWidth = 196;
  private readonly imgPadding = 22;
  private readonly imgLength = this.imgWidth + this.imgPadding;
  private readonly maxVisibleItems = 5;
  private readonly maxVisibleCarouselWidth =
    this.imgLength * this.maxVisibleItems;
  private isTouched: boolean | null = null;
  private touchStartX = 0;

  private resizeSubject = new Subject<number>();
  private currentPageSubject = new BehaviorSubject<number>(0);

  public ministers$ = this.ministersService
    .getMinisters({ page: 0, size: 10, query: '', sort: '' })
    .pipe(map(response => response.content));

  private options$: Observable<CarouselOptionsQueryModel> = combineLatest([
    this.resizeSubject.pipe(debounceTime(200)),
    this.ministers$,
  ]).pipe(
    map(([screenWidth, ministers]) => {
      const carouselWidth =
        screenWidth > this.maxVisibleCarouselWidth
          ? this.maxVisibleCarouselWidth
          : screenWidth;
      const totalListWidth = this.imgLength * ministers.length;
      const perPage = Math.floor(carouselWidth / this.imgLength);

      return {
        carouselWidth,
        totalListWidth,
        perPage: perPage > 0 ? perPage : 1,
        count: ministers.length,
      };
    })
  );

  public data$: Observable<CarouselDataQueryModel> = combineLatest([
    this.options$,
    this.currentPageSubject.asObservable(),
  ]).pipe(
    map(([options, currentPage]) => {
      const totalPages = Math.ceil(options.count / options.perPage);

      const newPage =
        currentPage > totalPages - 1 ? totalPages - 1 : currentPage;

      return {
        currentPage: newPage,
        totalPages,
        dots: [...Array(totalPages).keys()],
        isFirstPage: newPage === 0,
        isLastPage: newPage === totalPages - 1,
        options,
      };
    }),
    tap(data => {
      if (this.listEl) {
        this.slide(data);
      }
    }),
    shareReplay(1)
  );

  constructor(
    private ministersService: MinistersService,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.resizeSubject.next(window.innerWidth);
  }

  public onPrevClick(): void {
    this.slideLeft();
  }

  public onNextClick(): void {
    this.slideRight();
  }

  public onDotClick(page: number): void {
    this.currentPageSubject.next(page);
  }

  public onTouchStart(event: TouchEvent): void {
    this.touchStartX = event.touches[0].clientX;
  }

  public onTouchEnd(event: TouchEvent): void {
    const touchDifference = this.touchStartX - event.changedTouches[0].clientX;
    this.isTouched =
      touchDifference > 10 ? true : touchDifference < -10 ? false : null;

    if (this.isTouched) {
      this.slideRight();
    } else if (this.isTouched === false) {
      this.slideLeft();
    }
  }

  get listEl(): HTMLUListElement | undefined {
    return <HTMLUListElement>this.carouselList?.nativeElement;
  }

  private slide(data: CarouselDataQueryModel): void {
    const slideNo = data.currentPage * data.options.perPage;
    let distance = slideNo * this.imgLength;

    if (data.isLastPage) {
      distance = data.options.totalListWidth - data.options.carouselWidth;
    }

    if (data.isLastPage && (data.options.carouselWidth < this.maxVisibleCarouselWidth)) {
      distance += this.imgPadding + 4;
    }

    if (!this.listEl) return;

    if (this.isTouched !== null && !this.listEl.classList.contains('mobile')) {
      this.renderer.addClass(this.listEl, 'mobile');
    }

    this.renderer.setStyle(
      this.listEl,
      'transform',
      `translateX(-${distance}px)`
    );
  }

  private slideRight(): void {
    this.data$
      .pipe(
        take(1),
        tap(data => {
          if (data.isLastPage) return;
          this.currentPageSubject.next(data.currentPage + 1);
        })
      )
      .subscribe();
  }

  private slideLeft(): void {
    this.data$
      .pipe(
        take(1),
        tap(data => {
          if (data.isFirstPage) return;
          this.currentPageSubject.next(data.currentPage - 1);
        })
      )
      .subscribe();
  }
}
