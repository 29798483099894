import { Component, EventEmitter, Input, Output } from '@angular/core';

let uniqueId = 0;

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() label!: string;
  @Input() isChecked = false;
  @Output() clicked = new EventEmitter<boolean>();

  public uniqueId = uniqueId++;

  onClick(event: Event) {
    if (event && event.target instanceof HTMLInputElement) {
      const checked = (<HTMLInputElement>event.target).checked;
      this.clicked.emit(checked);
    }
  }
}
