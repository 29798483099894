export const plToEnChars: { [key: string]: string } = {
  ą: 'a',
  ćĆ: 'c',
  ę: 'e',
  łŁ: 'l',
  ń: 'n',
  óÓ: 'o',
  śŚ: 's',
  źŹ: 'z',
  żŻ: 'z',
};

export function replacePolishChars(text: string) {
  for (const key of Object.keys(plToEnChars)) {
    text = text.replace(new RegExp('[' + key + ']', 'g'), plToEnChars[key]);
  }
  return text;
}
