import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

  transform(value: string, limit = 500): string {
    if (limit >= value.length - 1) {
      return value;
    }
    
    const lastCharIndex = limit - 1;
    const isSpecialChar = /[\?.,:;_\-]/;
    const isSpace = /\s/;
    const isSpaceLastChar = isSpace.test(value.charAt(lastCharIndex));
    const isSpecialCharBeforeSpace = isSpecialChar.test(value.charAt(lastCharIndex - 1));
    let substring = value.substring(0, lastCharIndex);

    if (isSpaceLastChar && isSpecialCharBeforeSpace) {
        return substring;
    }

    if (isSpaceLastChar && !isSpecialCharBeforeSpace) {
      return `${substring}...`;
    }

    const spaceIndex = value.indexOf(' ', lastCharIndex);

    if (spaceIndex === -1) {
      return value;
    }

    substring = value.substring(0, spaceIndex);
    return isSpecialChar.test(value.charAt(spaceIndex - 1)) ? substring : `${substring}...`;
  }
}
