import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay, tap } from 'rxjs';
import { Image } from 'src/app/interfaces/image.interface';

@Component({
  selector: 'app-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GallerySliderComponent implements AfterViewInit {
  @ViewChild('slidesList') slidesList!: ElementRef;

  @Input() images: Image[] = [];
  @Input() set activeIndex(value: number) {
    this._activeIndexSubject.next(value);
  }

  @Output() imageChanged = new EventEmitter<number>(); 

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: Event) {
    if (event instanceof KeyboardEvent) {
      switch (event.key) {
        case 'ArrowLeft':
          this.slideLeft();
          break;
        case 'ArrowRight':
          this.slideRight();
          break;
      }
    }
  }

  private _activeIndexSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public readonly activeIndex$: Observable<number> = this._activeIndexSubject
    .asObservable()
    .pipe(
      tap(index => {
        this.slide(index);
        this.imageChanged.emit(index);
      }),
      shareReplay(1)
    );
  public readonly isFirstSlide$: Observable<boolean> = this.activeIndex$.pipe(
    map(index => index === 0),
    shareReplay(1)
  );
  public readonly isLastSlide$: Observable<boolean> = this.activeIndex$.pipe(
    map(index => index === this.images.length - 1),
    shareReplay(1)
  );

  private isTouched: boolean | null = null;
  private touchStartX = 0;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.slide(this._activeIndexSubject.getValue());
  }

  public selectImage(index: number): void {
    this._activeIndexSubject.next(index);
  }

  public onTouchStart(event: TouchEvent): void {
    this.touchStartX = event.touches[0].clientX;
  }

  public onTouchEnd(event: TouchEvent): void {
    const touchDifference = this.touchStartX - event.changedTouches[0].clientX;
    this.isTouched =
      touchDifference > 10 ? true : touchDifference < -10 ? false : null;

    if (this.isTouched) {
      this.slideRight();
    } else if (this.isTouched === false) {
      this.slideLeft();
    }
  }

  slideLeft() {
    const activeIndex = this._activeIndexSubject.getValue();
    if (activeIndex < 1) return;
    this._activeIndexSubject.next(activeIndex - 1);
  }

  slideRight() {
    const activeIndex = this._activeIndexSubject.getValue();
    if (activeIndex >= this.images.length - 1) return;
    this._activeIndexSubject.next(activeIndex + 1);
  }

  private slide(index: number) {
    if (!this.slidesList?.nativeElement) return;

    const proportions = this.images.length < 2 ? 0 : index / this.images.length;

    const left = -100 * proportions;

    if (
      this.isTouched !== null &&
      !this.slidesList.nativeElement.classList.contains('mobile')
    ) {
      this.renderer.addClass(this.slidesList.nativeElement, 'mobile');
    }

    this.renderer.setStyle(
      this.slidesList.nativeElement,
      'transform',
      `translateX(${left}%)`
    );
  }
}
