import { Pipe, PipeTransform } from '@angular/core';
import { replacePolishChars } from '../utils/polish-chars';
import { LocationMinisterInterface } from '../interfaces/memorial-location.interface';
import { MinisterListItem } from '../interfaces/minister-list.interface';

type ListOrMemorialMinister = MinisterListItem | LocationMinisterInterface;

@Pipe({
  name: 'ministerSlug',
})
export class MinisterSlugPipe<T extends ListOrMemorialMinister>
  implements PipeTransform
{
  transform(minister: T, idProp: keyof T): string {
    if (!minister.hasOwnProperty(idProp)) {
      return '';
    }

    const text =
      minister[idProp] +
      ',' +
      minister.firstName +
      ' ' +
      (minister.secondName || '') +
      ' ' +
      minister.lastName;
    return replacePolishChars(text).toLowerCase().trim().replace(/\s+/g, '-');
  }
}
