import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  map,
  shareReplay,
  take,
  tap,
} from 'rxjs';
import { YearPickerComponent } from '../year-picker/year-picker.component';
import { FormControl } from '@angular/forms';

interface YearRange {
  start: number | undefined;
  end: number | undefined;
}

@Component({
  selector: 'app-year-range-picker',
  templateUrl: './year-range-picker.component.html',
  styleUrls: ['./year-range-picker.component.scss'],
})
export class YearRangePickerComponent {
  @ViewChild('pickerFrom') pickerFrom!: YearPickerComponent;
  @ViewChild('pickerTo') pickerTo!: YearPickerComponent;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const isClickInside = this.getHostElement().contains(
      <HTMLElement>event.target
    );

    const target = <HTMLElement>event.target;

    if (!isClickInside && !target.classList.contains('year-picker__button')) {
      this.close();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: Event) {
    if (event instanceof KeyboardEvent) {
      switch (event.key) {
        case 'Escape':
          this.close();
          break;
      }
    }
  }

  @Output() onYearChange = new EventEmitter<YearRange>();

  @Input()
  set value(value: YearRange) {
    this.yearFromControl.setValue(value.start ? value.start.toString() : null);
    this.yearToControl.setValue(value.end ? value.end.toString() : null);
    this._valueSubject.next(value);
  }

  private _valueSubject = new BehaviorSubject<YearRange>({
    start: undefined,
    end: undefined,
  });
  public value$ = this._valueSubject
    .asObservable()
    .pipe(distinctUntilChanged(), shareReplay(1));

  public isFilled$: Observable<boolean> = this.value$.pipe(
    map(value => value.start !== undefined && value.end !== undefined),
    shareReplay(1)
  );

  readonly yearFromControl = new FormControl<string | null>(null);
  readonly yearToControl = new FormControl<string | null>(null);

  public valueLabel$: Observable<string> = this.value$.pipe(
    map(value => {
      if (value.start !== undefined && value.end !== undefined) {
        return `${value.start}-${value.end}`;
      }
      return 'od-do';
    })
  );

  private _isVisibleSubject = new BehaviorSubject<boolean>(false);
  public isVisible$: Observable<boolean> =
    this._isVisibleSubject.asObservable();

  constructor(private _elementRef: ElementRef) {}

  private parseValue(value: string | null): number | undefined {
    if (value === null) return undefined;

    if (/^[0-9]+$/.test(value)) return +value;

    return undefined;
  }

  onClearClick() {
    this.yearFromControl.setValue(null);
    this.yearToControl.setValue(null);
    this._valueSubject.next({
      start: undefined,
      end: undefined,
    });
    this.onChange();
  }

  toggleFrom() {
    this.pickerFrom.toggle();
    this.pickerTo.close();
  }

  toggleTo() {
    this.pickerFrom.close();
    this.pickerTo.toggle();
  }

  setFrom(value: number) {
    this.yearFromControl.setValue(value.toString());
    this._valueSubject.next({
      start: this.parseValue(this.yearFromControl.value),
      end: this.parseValue(this.yearToControl.value),
    });
    this.pickerFrom.close();
    this.pickerTo.open();
  }

  setTo(value: number) {
    this.yearToControl.setValue(value.toString());
    this._valueSubject.next({
      start: this.parseValue(this.yearFromControl.value),
      end: this.parseValue(this.yearToControl.value),
    });
    this.pickerTo.close();
    this.close();
  }

  close() {
    if (!this._isVisibleSubject.getValue()) return;
    this._isVisibleSubject.next(false);
    this.onChange();
  }

  toggle() {
    const isVisible = this._isVisibleSubject.getValue();
    this._isVisibleSubject.next(!isVisible);
  }

  onFromChange() {
    let value = this.yearFromControl.value;
    
    if (value === '') {
      this.yearFromControl.setValue(null);
    }

    if (value === null || value === '') {
      this.updateValue();
      this.onChange();
      return;
    }

    if (!/^[0-9]+$/.test(value)) return;

    const year = parseInt(value);
    const yearTo = this.yearToControl.value;

    if (year < 1 || year > 9999 || (yearTo && year > parseInt(yearTo))) return;

    this.updateValue();
    this.onChange();
  }

  onToChange() {
    let value = this.yearToControl.value;

    if (value === '') {
      this.yearToControl.setValue(null);
    }

    if (value === null || value === '') {
      this.updateValue();
      this.onChange();
      return;
    }

    if (!/^[0-9]+$/.test(value)) return;

    const year = parseInt(value);
    const yearFrom = this.yearFromControl.value;

    if (year < 1 || year > 9999 || (yearFrom && year < parseInt(yearFrom)))
      return;

    this.updateValue();
    this.onChange();
  }

  updateValue() {
    const value: YearRange = {
      start: this.parseValue(this.yearFromControl.value),
      end: this.parseValue(this.yearToControl.value),
    };

    this._valueSubject.next(value);
  }

  private onChange() {
    const currentValue = this._valueSubject.getValue();

    if (
      (currentValue.start === undefined && currentValue.end === undefined) ||
      (currentValue.start !== undefined && currentValue.end !== undefined)
    ) {
      this.onYearChange.emit(currentValue);
    }
  }

  onFromControlKeydown(event: Event) {
    if (event instanceof KeyboardEvent) {
      switch (event.key) {
        case 'Tab':
          if (event.shiftKey) {
            this.close();
          }
          break;
        case 'Enter':
          this.pickerFrom.toggle();
          break;
      }
    }
  }

  onToControlKeydown(event: Event) {
    if (event instanceof KeyboardEvent) {
      switch (event.key) {
        case 'Tab':
          if (!event.shiftKey) {
            this.pickerTo.isVisible$
              .pipe(
                take(1),
                tap(isVisible => {
                  if (!isVisible) {
                    this.close();
                  }
                })
              )
              .subscribe();
          }
          break;
        case 'Enter':
          this.pickerTo.toggle();
          break;
      }
    }
  }

  private getHostElement(): HTMLElement {
    return <HTMLElement>this._elementRef.nativeElement;
  }
}
