<div class="carousel" *ngIf="data$ | async as data">
  <!-- prev -->
  <button
    type="button"
    class="btn btn--plain carousel__action carousel__action--prev"
    [attr.aria-disabled]="data.isFirstPage ? true : null"
    [ngClass]="{ inactive: data.isFirstPage }"
    (click)="onPrevClick()">
    <span class="sr-only"> Poprzedni slajd </span>
    <span
      class="gov-icon gov-icon--arrow-left-thin carousel__icon"
      aria-hidden="true"></span>
  </button>

  <!-- next -->
  <button
    type="button"
    class="btn btn--plain carousel__action carousel__action--next"
    [attr.aria-disabled]="data.isLastPage ? true : null"
    [ngClass]="{ inactive: data.isLastPage }"
    (click)="onNextClick()">
    <span class="sr-only"> Następny slajd </span>
    <span
      class="gov-icon gov-icon--arrow-right-thin carousel__icon"
      aria-hidden="true"></span>
  </button>

  <!-- list -->
  <div class="carousel__inner">
    <ul class="carousel__list" #carouselList (touchstart)="onTouchStart($event)" (touchend)="onTouchEnd($event)">
      <ng-container *ngIf="ministers$ | async as ministers">
        <li class="carousel__item" *ngFor="let minister of ministers">
          <img
            class="carousel__image"
            [src]="minister.imageDTO.imageUrl"
            [alt]="
              minister.firstName +
              ' ' +
              minister.secondName +
              ' ' +
              minister.lastName
            "
            [ngClass]="{ grayscale: minister.dateOfDeath }" />

          <div class="carousel__person">
            <p class="carousel__person-name">
              {{ minister.firstName }}
              {{ minister.secondName }}
              {{ minister.lastName }}
            </p>
            <p class="carousel__person-role">
              {{ minister.functionName }}
            </p>
            <a
              [routerLink]="[
                '/',
                routeNames.MINISTERS,
                minister | ministerSlug : 'id'
              ]"
              class="link link--l">
              Zobacz więcej
            </a>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>

  <!-- dots -->
  <div class="carousel-dots">
    <ul class="carousel-dots__list">
      <li
        class="carousel-dots__item"
        *ngFor="let dot of data.dots"
        [ngClass]="{ active: dot === data.currentPage }">
        <button
          type="button"
          class="btn btn--plain carousel-dots__button"
          (click)="onDotClick(dot)">
          <span class="sr-only"
            >Przejdź do slajdu {{ dot + 1 }} z {{ data.dots.length }}</span
          >
        </button>
      </li>
    </ul>
  </div>
</div>
