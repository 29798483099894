import { Injectable } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormDialogsService {
  constructor(private dialog: Dialog) {}

  openDialog<T, D, R = NonNullable<D>>(
    component: ComponentType<T>,
    data: D,
    cssClass = 'dialog-lg'
  ): Observable<R> {
    return this.dialog
      .open<T, D>(component, {
        data,
        panelClass: cssClass,
        disableClose: true,
      })
      .closed.pipe(
        take(1),
        map(value => value as R)
      );
  }
}
