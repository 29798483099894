import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, combineLatest, map, of } from 'rxjs';
import { items } from 'src/app/config/faq.items';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqComponent {
  public items$ = of(items);
  public selectedIndexes$ = new BehaviorSubject<number[]>([]);
  public itemsWithState$ = combineLatest([this.items$, this.selectedIndexes$]).pipe(
    map(([items, selectedIndexes]) => {
      return items.map((item, index) => ({
        ...item,
        isVisible: selectedIndexes.includes(index)
      }))
    })
  );

  public toggle(index: number) {
    const currentIndexes = this.selectedIndexes$.getValue();
    const exisitingIndex = currentIndexes.indexOf(index);
    const updatedIndexes = (exisitingIndex === -1) ? [...currentIndexes, index] : currentIndexes.filter(i => i !== index);

    this.selectedIndexes$.next(updatedIndexes);
  }

  public trackByIndex(index: number) {
    return index;
  }
}
