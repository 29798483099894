import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { GovernmentsComponent } from './pages/governments/governments.component';
import { HomeComponent } from './pages/home/home.component';
import { MemberComponent } from './pages/member/member.component';
import { MembersComponent } from './pages/members/members.component';
import { MemorialComponent } from './pages/memorial/memorial.component';
import { CustomTitleStrategy } from './services/custom-title-strategy';
import { ministerNameResolver } from './services/minister-name.resolver';
import { RouteNames } from './config/route-names';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { membersResolver } from './pages/members/members.resolver';
import { memberResolver } from './pages/member/member.resolver';
import { governmentsResolver } from './pages/governments/governments.resolver';
import { memorialResolver } from './pages/memorial/memorial.resolver';
import { governmentNameResolver } from './services/government-name.resolver';

const routes: Routes = [
  { path: '', component: HomeComponent, title: 'Strona główna' },
  { path: RouteNames.ABOUT, component: AboutComponent, title: 'O projekcie' },
  {
    path: RouteNames.MINISTERS,
    title: 'Członkowie Rady Ministrów',
    children: [
      {
        path: '',
        component: MembersComponent,
        resolve: { members: membersResolver },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      },
      {
        path: ':id',
        component: MemberComponent,
        title: ministerNameResolver,
        resolve: { details: memberResolver },
      },
    ],
  },
  {
    path: RouteNames.MEMORIALS,
    resolve: { memorial: memorialResolver },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    component: MemorialComponent,
    title: 'Mapa miejsc pamięci',
  },
  {
    path: RouteNames.GOVERNMENTS_DETAILS,
    component: GovernmentsComponent,
    resolve: { governments: governmentsResolver },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    title: governmentNameResolver,
  },
  { path: RouteNames.CONTACT, component: ContactComponent, title: 'Kontakt' },
  {
    path: RouteNames.PAGE_NOT_FOUND,
    component: PageNotFoundComponent,
    title: 'Nie znaleziono strony',
  },
  {
    path: RouteNames.SERVER_ERROR,
    component: ServerErrorComponent,
    title: 'Błąd serwera',
  },
  { path: '**', redirectTo: RouteNames.PAGE_NOT_FOUND },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: CustomTitleStrategy }],
})
export class AppRoutingModule {}
