<div class="container-content">
  <!-- breadcrumbs -->
  <app-breadcrumbs></app-breadcrumbs>

  <ng-container *ngIf="data$ | async as data">
    <!-- page heading -->
    <h1 class="heading-2 page-heading">
      Znajdź ministrów
      <span class="d-block">Rzeczypospolitej Polskiej</span>
    </h1>

    <!-- search form -->
    <app-search
      [searchText]="data.query"
      [isAdvancedSearch]="true"
      (searchTextChanged)="onSearchChange($event)"
      (searchTextCleared)="onSearchClear()">
      <ng-container description>
        Dowiedz się więcej o ministrach Rzeczypospolitej Polskiej.
        <span class="block-desktop">
          Skorzystaj z wyszukiwarki, aby znaleźć konkretnych ministrów.
        </span>
      </ng-container>
    </app-search>

    <!-- filters - temp hidden -->
    <ng-container *ngIf="qpFilters$ | async as qpFilters">
      <app-minister-list-filters
        [filters]="qpFilters"
        (onFiltersChange)="onFiltersChange($event)"></app-minister-list-filters>
    </ng-container>

    <!-- no results -->
    <section class="no-results" id="no-results" *ngIf="!data.items.length">
      <h2 class="heading-3 no-results__heading">Brak wyników wyszukiwania</h2>

      <p class="no-results__description">
        Nie znaleźliśmy żadnych pasujących wyników wyszukiwania.
      </p>
      <ul class="no-results__list">
        <li class="no-results__item">
          <ng-container
            >Spróbuj użyć innego zapytania w wyszukiwarce</ng-container
          >
        </li>
        <li class="no-results__item">
          <ng-container>Sprawdź, czy w zapytaniu nie ma literówki</ng-container>
        </li>
        <li class="no-results__item">
          <ng-container>Spróbuj zmienić filtrowanie wyników</ng-container>
        </li>
      </ul>
    </section>

    <!-- results -->
    <section class="search-results" *ngIf="data.items.length">
      <h2 class="heading-3 results__found-heading" *ngIf="data.items.length">
        Znaleźliśmy {{ data.totalItems }}
        {{ data.totalItems | declinate : 'wynik' : 'wyniki' : 'wyników' }}
        wyszukiwania
      </h2>

      <!-- result list options -->
      <div class="results__options">
        <!-- result on page -->
        <div class="results__per-page">
          <app-dropdown
            [items]="(itemsPerPage$ | async)!"
            [selectedValue]="data.pageSize.toString()"
            [selectedItemAsLabel]="true"
            (onDropdownItemClick)="onPerPageChange($event)">
          </app-dropdown>
        </div>

        <!-- pagination -->
        <app-pagination
          [currentPage]="data.page"
          [totalPages]="data.totalPages"
          (pageChange)="onPageChange($event)"></app-pagination>

        <!-- sort -->
        <div class="results__sort">
          <span class="results__sort-label"> Sortowanie: </span>
          <app-dropdown
            [items]="(sortOptions$ | async)!"
            [selectedValue]="data.sort"
            [selectedItemAsLabel]="true"
            (onDropdownItemClick)="onSortChange($event)">
          </app-dropdown>
        </div>
      </div>

      <!-- results list -->
      <ul>
        <li class="results__item" *ngFor="let item of data.items">
          <div class="results__details results__details--personal">
            <img
              [src]="item.imageDTO.imageUrl"
              class="rounded results__image"
              [alt]="item.imageDTO.alternativeDescription"
              [ngClass]="{ grayscale: item.dateOfDeath }" />

            <h3 class="heading-4 m-0 results__item-heading">
              {{ item.firstName }}
              {{ item.secondName }}
              {{ item.lastName }}
            </h3>
          </div>

          <!-- professional -->
          <div class="results__details results__details--professional">
            <!-- dates -->
            <div class="results__details-row">
              <span class="labelled-group">
                Data urodzenia
                <span class="labelled-group__value">
                  <ng-container *ngIf="item.dateOfBirth">
                    {{ item.dateOfBirth }}
                  </ng-container>
                  <abbr title="brak danych" *ngIf="!item.dateOfBirth"
                    >b.d.</abbr
                  >
                </span>
              </span>
              <span class="labelled-group" *ngIf="item.dateOfDeath">
                Data śmierci
                <span class="labelled-group__value">
                  {{ item.dateOfDeath }}
                </span>
              </span>
            </div>

            <div class="results__details-row">
              <span class="labelled-group">
                {{
                  item.currentFunction ? 'Aktualna funkcja' : 'Ostatnia funkcja'
                }}
                <span class="labelled-group__value">
                  {{ item.functionName }}
                </span>
              </span>
              <span class="labelled-group">
                Instytutcja
                <span class="labelled-group__value">
                  {{ item.institutionName }}
                </span>
              </span>
            </div>

            <div class="results__details-row">
              <span class="labelled-group">
                Rząd
                <span class="labelled-group__value">
                  {{ item.governmentName }}
                </span>
              </span>
              <span class="labelled-group">
                Okres historyczny
                <span class="labelled-group__value">
                  {{ item.historicalPeriodName }}
                </span>
              </span>
            </div>
          </div>

          <!-- link -->
          <a
            [routerLink]="[
              '/',
              routeNames.MINISTERS,
              item | ministerSlug : 'id'
            ]"
            class="link link--xl results__link">
            Zobacz więcej
            <span class="sr-only"
              >o
              {{ item.firstName }}
              {{ item.secondName }}
              {{ item.lastName }}
            </span>
          </a>
        </li>
      </ul>

      <!-- result list options -->
      <div class="results__options results__options--bottom">
        <!-- result on page -->
        <div class="results__per-page">
          <app-dropdown
            [items]="(itemsPerPage$ | async)!"
            [selectedValue]="data.pageSize.toString()"
            [selectedItemAsLabel]="true"
            (onDropdownItemClick)="onPerPageChange($event)">
          </app-dropdown>
        </div>

        <!-- pagination -->
        <app-pagination
          [currentPage]="data.page"
          [totalPages]="data.totalPages"
          (pageChange)="onPageChange($event)"></app-pagination>
      </div>
    </section>
  </ng-container>
</div>
