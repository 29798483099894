import { Component } from '@angular/core';
import { RouteNames } from 'src/app/config/route-names';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  public routeNames = RouteNames;
}
