import { MinisterListFilters } from '../model/minister-list-filters';

export function clearEmptyFilters(params: Partial<MinisterListFilters>) {
  return Object.keys(params).reduce((list, prop) => {
    const key = prop as keyof MinisterListFilters;

    return params[key] === undefined
      ? { ...list }
      : { ...list, [key]: params[key] };
  }, {});
}
