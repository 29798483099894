import { FaqItem } from '../interfaces/faq.interface';

export const items: FaqItem[] = [
  {
    question:
      'Dolorem modi consequuntur voluptatibus debitis repellendus nobis odit nisi repellat.',
    answer:
      'Temporibus, vero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem modi consequuntur voluptatibus debitis repellendus nobis odit nisi repellat. Temporibus, vero. Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    question:
      'Temporibus, vero ipsum dolor sit amet consectetur adipisicing. Dolorem modi consequuntur voluptatibus debitis repellendus nobis odit nisi repellat.',
    answer:
      'Dolorem modi consequuntur voluptatibus debitis repellendus nobis odit nisi repellat. Temporibus, vero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem modi consequuntur voluptatibus debitis repellendus nobis odit nisi repellat. Temporibus, vero. Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    question:
      'Temporibus, vero ipsum dolor sit amet consectetur adipisicing. Dolorem modi consequuntur voluptatibus debitis repellendus nobis odit nisi repellat.',
    answer:
      'Dolorem modi consequuntur voluptatibus debitis repellendus nobis odit nisi repellat. Temporibus, vero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem modi consequuntur voluptatibus debitis repellendus nobis odit nisi repellat. Temporibus, vero. Lorem ipsum dolor sit amet consectetur adipisicing elit.Dolorem modi consequuntur voluptatibus debitis repellendus nobis odit nisi repellat. Temporibus, vero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem modi consequuntur voluptatibus debitis repellendus nobis odit nisi repellat. Temporibus, vero. Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
];
