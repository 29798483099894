import { Params } from '@angular/router';
import { MinisterListFilterQueryParam } from 'src/app/core/filters';
import { DropdownItem } from 'src/app/interfaces/dropdown-item.interface';

export class MembersFiltersQueryModel {
  public static readonly defaultPage = 1;
  private static readonly defaultPerPage = 10;
  public static readonly defaultSortColumn = 'lastName';
  public static readonly defaultSort = `${this.defaultSortColumn},asc`;

  private constructor(
    public readonly page: number,
    public readonly pageSize: number,
    public readonly sort: string,
    public readonly query: string,
    // filters
    public readonly functionName: string,
    public readonly governmentName: string,
    public readonly historicalPeriodName: string,
    public readonly institutionName: string | undefined,
    public readonly institutionTermBeginning: string | undefined,
    public readonly institutionTermEnd: string | undefined,
    public readonly isDead: boolean,
    public readonly isCurrentFunction: boolean,
    public readonly yearFromOffice: number | undefined,
    public readonly yearToOffice: number | undefined
  ) {}

  static fromQueryParams(queryParams: Params, sortOptions: DropdownItem[]) {
    let page = +queryParams['p'] || this.defaultPage;
    let pageSize = +queryParams['ps'] || this.defaultPerPage;
    let sort = queryParams['s'] || this.defaultSort;
    const query = queryParams['q'] || '';

    // filters
    const functionName = queryParams[MinisterListFilterQueryParam.FUNCT] || '';
    const governmentName =
      queryParams[MinisterListFilterQueryParam.GOVERNMENT] || '';
    const historicalPeriodName =
      queryParams[MinisterListFilterQueryParam.HISTORIC_PERIOD] || '';
    const institutionName =
      queryParams[MinisterListFilterQueryParam.INSTITUTION_NAME];
    const institutionTermBeginning =
      queryParams[MinisterListFilterQueryParam.INSTITUTION_BEGINNING];
    const institutionTermEnd =
      queryParams[MinisterListFilterQueryParam.INSTITUTION_END];

    const isDead =
      (queryParams[MinisterListFilterQueryParam.ONLY_DEAD] &&
      queryParams[MinisterListFilterQueryParam.ONLY_DEAD].toLowerCase() ===
        'true'
        ? true
        : false) || false;
    const isCurrentFunction =
      (queryParams[MinisterListFilterQueryParam.ONLY_CURRENT_FUNCTION] &&
      queryParams[
        MinisterListFilterQueryParam.ONLY_CURRENT_FUNCTION
      ].toLowerCase() === 'true'
        ? true
        : false) || false;
    const yearFromOffice = queryParams[MinisterListFilterQueryParam.YEAR_START];
    const yearToOffice = queryParams[MinisterListFilterQueryParam.YEAR_END];
    /// filters

    if (!this.checkValidity(page, pageSize, sort, sortOptions)) {
      page = this.defaultPage;
      pageSize = this.defaultPerPage;
      sort = this.defaultSort;
    }

    return new this(
      page,
      pageSize,
      sort,
      query,
      functionName,
      governmentName,
      historicalPeriodName,
      institutionName,
      institutionTermBeginning,
      institutionTermEnd,
      isDead,
      isCurrentFunction,
      yearFromOffice,
      yearToOffice
    );
  }

  private static checkValidity(
    page: number,
    pageSize: number,
    sort: string,
    sortOptions: DropdownItem[]
  ): boolean {
    return (
      page >= 1 &&
      !isNaN(page) &&
      pageSize >= 1 &&
      !isNaN(pageSize) &&
      sortOptions.some(item => item.value === sort)
    );
  }
}
