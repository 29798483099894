import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MinisterListFilters, clearEmptyFilters } from 'src/app/core/filters';
import { MemorialLocationInterface } from '../../interfaces/memorial-location.interface';
import { CacheService } from '../cache/cache.service';

@Injectable({
  providedIn: 'root',
})
export class MemorialsLocationsService {
  constructor(private _http: HttpClient, private _cache: CacheService) {}

  public getMinisterMemorials(
    ministerId: string
  ): Observable<Array<MemorialLocationInterface>> {
    return this._cache.remember(
      `MemorialsLocationsService-getMinisterMemorials-${ministerId}`,
      this._http.get<MemorialLocationInterface[]>(
        `page/ministers/${ministerId}/memorials/map`
      )
    );
  }

  public getAllMemorials(
    params: Partial<MinisterListFilters> = {}
  ): Observable<MemorialLocationInterface[]> {
    const updatedParams = clearEmptyFilters(params);

    return this._cache.remember(
      `MemorialsLocationsService-getAllMemorials-${JSON.stringify(params)}`,
      this._http.get<MemorialLocationInterface[]>(`page/memorials/map`, {
        params: new HttpParams({ fromObject: updatedParams as {} }),
      })
    );
  }
}
