import { MinisterListItem } from 'src/app/interfaces/minister-list.interface';
import { MembersFiltersQueryModel } from './members-filters.query-model';
import { PageableContent } from 'src/app/shared/pagination/pageable-data';

export class MembersQueryModel {
  private constructor(
    public readonly items: MinisterListItem[],
    public readonly page: number,
    public readonly pageSize: number,
    public readonly totalItems: number,
    public readonly totalPages: number,
    public readonly query: string,
    public readonly governmentName: string,
    public readonly sort: string
  ) {}

  static fromResponse(
    response: PageableContent<MinisterListItem[]>,
    queryParamsModel: MembersFiltersQueryModel
  ) {
    return new this(
      response.content,
      response.pageable.pageNumber,
      response.pageable.pageSize,
      response.totalElements,
      response.totalPages,
      queryParamsModel.query,
      queryParamsModel.governmentName,
      queryParamsModel.sort
    );
  }
}
