<div class="grave-gallery" *ngIf="images.length">
  <ng-container *ngIf="{ index: (activeIndex$ | async) || 0 } as activeImage">
    <!-- active image preview -->
    <div class="grave-gallery-image-block">
      <img
        class="grave-gallery-image"
        [src]="images[activeImage.index].imageUrl"
        [alt]="images[activeImage.index].alternativeDescription"
        (click)="showModal(activeImage.index)" />

      <p *ngIf="images[activeImage.index].description" class="image-description">
        {{ images[activeImage.index].description }}
      </p>

      <p *ngIf="images[activeImage.index].license" class="image-description">
        Autor/licencja: <span class="image-description-bold">{{ images[0].license }}</span>
      </p>
    </div>

    <!-- thumbnails -->
    <ul class="grave-thumbnail-list">
      <li
        class="grave-thumbnail-item"
        *ngFor="let img of images; let i = index">
        <button
          type="button"
          class="rounded grave-thumbnail-btn"
          [ngClass]="{'active': i === activeImage.index}"
          (click)="selectImage(i)">
          <img
            class="rounded grave-thumbnail"
            [src]="img.thumbnailUrl"
            [alt]="img.alternativeDescription" />
        </button>
      </li>
    </ul>
  </ng-container>
</div>