import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { MembersQueryModel } from './members.query-model';
import { DropdownItem } from 'src/app/interfaces/dropdown-item.interface';
import { MembersFiltersQueryModel } from './members-filters.query-model';
import { inject } from '@angular/core';
import { MinistersService } from 'src/app/services/ministers/ministers.service';
import { map } from 'rxjs/operators';

export const membersResolver: ResolveFn<{
  list: MembersQueryModel;
  sortOptions: DropdownItem[];
}> = (activatedRoute: ActivatedRouteSnapshot) => {
  const sortOptions: DropdownItem[] = [
    {
      label: 'Nazwisko A-Z',
      value: `${MembersFiltersQueryModel.defaultSortColumn},asc`,
    },
    {
      label: 'Nazwisko Z-A',
      value: `${MembersFiltersQueryModel.defaultSortColumn},desc`,
    },
  ];

  const queryParamsModel = MembersFiltersQueryModel.fromQueryParams(
    activatedRoute.queryParams,
    sortOptions
  );

  return inject(MinistersService)
    .getMinisters({
      ...queryParamsModel,
      size: queryParamsModel.pageSize,
    })
    .pipe(
      map(response => ({
        list: MembersQueryModel.fromResponse(response, queryParamsModel),
        sortOptions,
      }))
    );
};
