<div class="container-layout">
  <!-- breadcrumbs -->
  <app-breadcrumbs></app-breadcrumbs>

  <div class="memorial">
    <!-- page heading -->
    <h1 class="heading-1 page-heading">
      Mapa miejsc pamięci ministrów
      <span class="d-block">Rzeczypospolitej Polskiej</span>
    </h1>

    <app-search
      [isAdvancedSearch]="true"
      (searchTextChanged)="onSearchChange($event)"
      (searchTextCleared)="onSearchClear()">
      <ng-container description>
        Skorzystaj z wyszukiwarki, aby znaleźć miejsca pamięci konkretnych
        ministrów.
      </ng-container>
    </app-search>
    <ng-container *ngIf="qpFilters$ | async as qpFilters">
      <app-minister-list-filters
        [filters]="qpFilters"
        (onFiltersChange)="onFiltersChange($event)"></app-minister-list-filters>
    </ng-container>
    <!-- map -->
    <div *ngIf="memorials$ | async as memorials" class="memorial__map">
      <app-map
        class="memorial__map-container"
        [memorials]="memorials"
        [showDetails]="true"></app-map>
    </div>
  </div>
</div>
