import { Component } from '@angular/core';
import { ProgressBarService } from 'src/app/services/progress-bar.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  public isDataLoading$ = this.progressBarService.progress$;
  
  constructor(private progressBarService: ProgressBarService) {}
}
