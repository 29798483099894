import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Dictionaries } from '../interfaces/dictionaries.model';
import { sortArr, sortArrByDate, sortByNameAndDate } from '../utils/dates';

export interface GovernmentDictionaryDTO {
  readonly name: string;
  readonly termBeginning: string;
  readonly termEnd: string;
}

export interface InstitutionDictionaryDTO {
  readonly name: string;
  readonly termBeginning: string;
  readonly termEnd: string;
}

export interface DictionariesDTO {
  readonly institutions: InstitutionDictionaryDTO[];
  readonly governments: GovernmentDictionaryDTO[];
  readonly historicalPeriodNames: string[];
  readonly functionNames: string[];
}

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  constructor(private _httpClient: HttpClient) {}

  public getDictionaries(): Observable<Dictionaries> {
    return this._httpClient.get<DictionariesDTO>('page/ministers/filters').pipe(
      map(dictionaries => ({
        departments: sortByNameAndDate(dictionaries.institutions, {
          nameKey: 'name',
          dateKey: 'termBeginning',
          isDescending: false,
        }),
        governments: sortArrByDate(dictionaries.governments, {
          dateField: 'termBeginning',
          isDescending: false,
        }),
        historicalPeriodNames: sortArr(
          dictionaries.historicalPeriodNames,
          false
        ),
        functionNames: sortArr(dictionaries.functionNames, false),
      }))
    );
  }
}
