import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouteNames } from 'src/app/config/route-names';
import { DropdownItem } from 'src/app/interfaces/dropdown-item.interface';
import { GovernmentsQueryModel } from './governments.query-model';
import { GovernmentsFiltersQueryModel } from './governments-filters.query-model';

@Component({
  selector: 'app-governments',
  templateUrl: './governments.component.html',
  styleUrls: ['./governments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GovernmentsComponent {
  public readonly routeNames = RouteNames;

  public sortOptions$: Observable<DropdownItem[]> =
    this.activatedRoute.data.pipe(
      map(data => {
        return data['governments']['sortOptions'] as DropdownItem[];
      })
    );

  public data$: Observable<GovernmentsQueryModel> =
    this.activatedRoute.data.pipe(
      map(data => {
        return data['governments']['query'] as GovernmentsQueryModel;
      })
    );

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  public onSortChange(item: DropdownItem): void {
    this.updateQueryParams({ sort: item.value });
  }

  private updateQueryParams(
    params: Partial<GovernmentsFiltersQueryModel>
  ): void {
    const queryParams: Params = {};

    if (params.sort !== undefined) {
      queryParams['s'] = params.sort;
    }

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
