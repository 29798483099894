<nav class="breadcrumbs" aria-label="Breadcrumbs">
  <ol class="breadcrumbs__list">
    <li class="breadcrumbs__item">
      <a [routerLink]="['/']" class="breadcrumbs__link"> Strona główna </a>
    </li>
    <li
      *ngFor="let item of breadCrumbs$ | async; let last = last"
      class="breadcrumbs__item">
      <a
        *ngIf="!last; else current"
        [routerLink]="[item.url]"
        class="breadcrumbs__link">
        {{ item.title }}
      </a>
      <ng-template #current>{{ item.title }}</ng-template>
    </li>
  </ol>
</nav>
