import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'declinate',
})
export class DeclinatePipe implements PipeTransform {
  transform(
    value: number,
    singular: string,
    plural: string,
    pluralGenitiv: string
  ): string {
    if (value === 1) {
      return singular;
    }

    if (
      value % 10 >= 2 &&
      value % 10 <= 4 &&
      (value % 100 < 10 || value % 100 >= 20)
    ) {
      return plural;
    }

    return pluralGenitiv;
  }
}
