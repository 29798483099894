import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, share, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private readonly _cached = new Map<string, Observable<any>>();

  public remember<T>(key: string, callback: Observable<T>): Observable<T> {
    const item = this._cached.get(key);

    if (item !== undefined) {
      return item;
    }

    this._cached.set(
      key,
      callback.pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnComplete: () => timer(60 * 1000),
        })
      )
    );

    return this._cached.get(key)!;
  }
}
