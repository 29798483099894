<article class="dialog">
  <button class="dialog-close-button" (click)="onDialogClose()">
    <span class="sr-only">Zamknij okno dialogowe</span>
    <span class="dialog-close-icon" aria-hidden="true"></span>
  </button>

  <main class="dialog-content">
    <app-gallery-slider
      [images]="data.images"
      [activeIndex]="data.activeIndex"
      (imageChanged)="onImageChange($event)"></app-gallery-slider>
  </main>
</article>
