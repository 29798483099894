import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { HistoricalPeriodsService } from './historical-periods/historical-periods.service';

export const governmentNameResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot
) => {
  const slug = route.paramMap.get('slug');

  if (!slug) return of('Lista rządów');

  return inject(HistoricalPeriodsService)
    .getOne(slug)
    .pipe(
      map(period => period.name),
      catchError(_ => of('Lista rządów'))
    );
};
