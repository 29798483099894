<ul class="faq">
  <li class="faq__item" *ngFor="let item of itemsWithState$ | async; let index = index; trackBy: trackByIndex">
    <p class="faq__question">
      {{ item.question }}

      <!-- toggle -->
      <button
        type="button"
        class="btn btn--plain faq__toggle"
        (click)="toggle(index)"
        [attr.aria-expanded]="item.isVisible ? true : false"
        [attr.aria-controls]="'faq-answer-' + index">
        {{ item.isVisible ? 'Ukryj' : 'Zobacz' }}
        <span class="faq__toggle-part"> odpowiedź </span>
        <span
          class="gov-icon gov-icon--arrow-right-2-center faq__toggle-icon"
          [ngClass]="{ 'faq__toggle-icon--rotate': item.isVisible  }"></span>
      </button>
    </p>

    <!-- answer -->
    <div
      class="faq__answer"
      [ngClass]="{ 'visibility-animated': item.isVisible }"
      [id]="'faq-answer-' + index">
      <p class="faq__answer-text">
        {{ item.answer }}
      </p>
    </div>
  </li>
</ul>
